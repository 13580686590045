import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import Img1 from '../../assets/images/radio-therapy/Img1.webp';
import MiddleBannerImage from '../../assets/images/radio-therapy/MiddleBg.webp';
import { Link } from 'react-router-dom';
import FAQsSection from '../../components/FAQsSection';
import CommonBanner from '../../components/CommonBanner';
import BannerImg from '../../assets/images/radio-therapy/BannerImg.webp';
import { CircleFill } from 'react-bootstrap-icons';
import Bullets from '../../components/common/Bullets';
import { MetaTags } from 'react-meta-tags';

const BreathTests = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
      <MetaTags>
        <title> Radio Frequency Ablation: Empower Your Health for Pain Relief </title> 
        <meta name="description" content="Tired of managing pain? Choose Radio Frequency Ablation for long-lasting relief. Reach out now to schedule your procedure." />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Radio Frequency Ablation: Empower Your Health for Pain Relief" />
        <meta property="og:description" content="Tired of managing pain? Choose Radio Frequency Ablation for long-lasting relief. Reach out now to schedule your procedure." />
        <meta property="og:url" content="https://northshoredigestivemedicinepc.com/" />
        <meta property="og:site_name" content="NSDM" />
        <meta property="og:image" content="https://northshoredigestivemedicinepc.com/og.webp" />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
        <meta property="og:image:secure_url" content="https://northshoredigestivemedicinepc.com/og.webp" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content="Tired of managing pain? Choose Radio Frequency Ablation for long-lasting relief. Reach out now to schedule your procedure." />
        <meta name="twitter:title" content="Radio Frequency Ablation: Empower Your Health for Pain Relief" />
        <meta name="twitter:image" content="https://northshoredigestivemedicinepc.com/og.webp"/>
      </MetaTags>
    <Header/>

    <CommonBanner
    Title="Radio Frequency Ablation"
    Image={BannerImg}
    />
    
    <section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary text-lg-center">
      <div className="row justify-content-center">
        <div className="col-11">
          <div className=''>
            <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            Radio Frequency Ablation Therapy
            </h2>
            <h3 className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm pt-2"> Barrett's Esophagus Defined
            </h3>
            <div className='pt-4'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            Barrett's esophagus is a condition characterized by changes in the lining of the esophagus, primarily caused by prolonged exposure to stomach acid. This alteration in cell structure elevates the risk of developing esophageal cancer significantly. Studies indicate that individuals with Barrett's esophagus face a 50-80 times higher likelihood of developing esophageal cancer compared to the general population.
            <div className='py-2'/> 
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>
    
    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-evenly align-items-center gy-md-4 ">
        <div className="col-11 col-lg-6">
          <div className=''>
          <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            Risk Factors for Barrett's Esophagus:
            </h2>
            <div className='pt-4'/>
            <div className='fs-ss-18 fw-500 lh-para'>
            <Bullets
            Title="Chronic heartburn or acid reflux"
            />
            <Bullets
            Title="Smoking"
            />
            <Bullets
            Title="Family history of esophageal cancer"
            />
            <Bullets
            Title="Gender and Age"
            />
            <Bullets
            Title="Race"
            />                                                      
            </div>
            <div className='py-2'/>
            This procedure is conducted in collaboration with Stony Brook University Hospital.
          </div>
        </div>
        <div className="col-10 col-lg-5">
        <img className='w-100 px-lg-5' src={Img1} alt="" />        
        </div>
      </div>
    </section>

    <section className='pt-md-5 pt-3 mt-lg-5'/>

    <section id='website-responsive' className="container-fluid bg-digestive bg-digestive7 py-5 row justify-content-center gx-0">
    <section className="container-lg text-ss-secondary text-lg-center py-5 my-auto">
      <div className="row justify-content-center">
        <div className="col-11">
          <div className=''>
            <h2 className='fs-ss-40 fw-600 lh-sm text-black'>
            Redefine Digestive Health with RFA
            </h2>
            <div className="pt-4 pt-lg-5">
            <div className='fs-ss-18 fw-500 lh-para'>
            If you'd like to learn more about procedures or to <br className='d-none d-md-block'/> find out if you are a fit candidate or not, please contact us!
            </div>
            <div className='pt-4 pt-lg-5'/>
          <Link to="/book-an-appointment" className='text-decoration-none py-md-2 py-1 fw-500 btn-transparent-dark text-ss-secondary rounded-5 fs-ss-16 px-3 border-dark border-1 border'>
            Book an Appointment
          </Link>          
          </div>
            </div>
          </div>
        </div>   
    </section>
    </section>

    <section className='pt-5 mt-lg-4'/>

    <section id='website-responsive' className="container-lg text-ss-secondary text-lg-center">
      <div className="row justify-content-center">
        <div className="col-11">
          <div className=''>
            <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            RFAT At North Shore Digestive Medicine  
            </h2>
            <div className='pt-4'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            At North Shore, we specialize in providing comprehensive care for patients with Barrett's esophagus, including advanced treatments like radio frequency ablation (RFA) therapy. Our team of experienced gastroenterologists and medical professionals provides personalized care and effective interventions to manage Barrett's esophagus and reduce the risk of esophageal cancer. If you or a loved one is diagnosed with Barrett's esophagus, schedule a consultation with us today to explore your treatment options and receive the expert care you deserve. Your journey to better digestive health starts here at North Shore Digestive Medicine.
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-4'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
    <div className='fs-ss-30 fw-600 fw-600 lh-sm text-center pb-4'>
      FAQS
      </div>
      <div className="row justify-content-center text-center">
        <div className="col-11 col-lg-11">
          <FAQsSection
          Title="What is radio frequency ablation (RFA) therapy for Barrett's esophagus? "
          Para="Radio frequency ablation (RFA) therapy is a minimally invasive procedure used to treat Barrett's esophagus by ablating abnormal cells from the lining of the esophagus. It involves the application of controlled heat energy to the affected area, which destroys the abnormal cells and promotes healthy tissue growth."
          />

          <FAQsSection
          Title="Is radio frequency ablation (RFA) therapy painful? "
          Para="RFA therapy is typically well-tolerated by patients and is performed under sedation or anesthesia to minimize discomfort. Some patients may experience mild discomfort or soreness following the procedure, but this is usually temporary and can be managed with over-the-counter pain medications."
          />

          <FAQsSection
          Title="How effective is radio frequency ablation (RFA) therapy for Barrett's esophagus? "
          Para="Radiofrequency ablation therapy is highly effective in treating Barrett's esophagus and reducing the risk of esophageal cancer. Studies have demonstrated significant regression of Barrett's tissue and a reduced risk of disease progression with RFA therapy."
          />

          <FAQsSection
          Title="Are there any risks or complications associated with radio frequency ablation (RFA) therapy? "
          Para="While radiofrequency ablation therapy is considered safe, like any medical procedure, there are potential risks and complications. These may include bleeding, perforation of the esophagus, stricture formation (narrowing of the esophagus), and post-procedure discomfort. However, these risks are rare and can be minimized with proper technique and patient selection."
          />

          <FAQsSection
          Title="How many Radiofrequency ablation (RFA) therapy sessions are typically required? "
          Para="The number of RFA therapy sessions needed depends on the extent of Barrett's esophagus and the response to treatment. Multiple sessions are usually required to eradicate the abnormal tissue. Your healthcare provider will develop a personalized treatment plan based on your individual needs and response to therapy."
          />

        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>

    <Footer/>
    </>
  );
}

export default BreathTests;
