
//App.js 
import React, { useEffect, useState } from 'react'; 
import { Helmet } from 'react-helmet';

function BMI2() { 
    const [heightValue, setHeightValue] = useState(''); 
    const [weightValue, setWeightValue] = useState(''); 
    const [bmiValue, setBmiValue] = useState(''); 
    const [bmiMessage, setBmiMessage] = useState(''); 
  
    const calculateBmi = () => { 
        if (heightValue && weightValue) { 
            const heightInInches = heightValue; 
            const bmi = ((weightValue / (heightInInches * heightInInches)) * 703).toFixed(2); 
            setBmiValue(bmi); 
  
            let message = ''; 
            if (bmi < 18.5) { 
                message = 'You are Underweight'; 
            } else if (bmi >= 18.5 && bmi < 25) { 
                message = 'You are Normal weight'; 
            } else if (bmi >= 25 && bmi < 30) { 
                message = 'You are Overweight'; 
            } else { 
                message = 'You are Obese'; 
            } 
            setBmiMessage(message); 
        } else { 
            setBmiValue(''); 
            setBmiMessage(''); 
        } 
    }; 


    return ( 
      <>
      <div className="">
      <div className="py-2" />
            <div className="text-start"> 
                <label className='pb-1' htmlFor="height">Enter Your Height (inches):</label> <br className='d-block' />
                <input 
                    type="number"
                    id="height"
                    value={heightValue} 
                    onChange={(e) => setHeightValue(e.target.value)}
                    className='px-2 border-dark w-100 form-control shadow-sm' 
                    placeholder='Enter Height'
                /> 
            </div> 
            <div className="py-2" />
            <div className="text-start"> 
                <label className='pb-1' htmlFor="weight">Enter Your Weight (lbs):</label> <br className='d-block' />
                <input 
                    type="number"
                    id="weight"
                    value={weightValue} 
                    onChange={(e) => setWeightValue(e.target.value)}
                    className='px-2 border-dark w-100 form-control shadow-sm' 
                    placeholder='Enter Weight'
                /> 
            </div> 
            <div className="py-2" />
            <button className="calculate-btn btn-dark-transparent rounded-5 px-4 py-2 fs-ss-16 fw-500" onClick={calculateBmi}> 
                Calculate BMI
            </button> 
            <div className="py-2" />

            {bmiValue && bmiMessage && ( 
                <div className="result lh-sm"> 
                    <p className='fw-600 pt-3 mb-1'> 
                        Your BMI is <span className="bmi-value">{bmiValue}</span> 
                    </p> 
                    <p className='fw-600'> 
                        <span className="bmi-message"> { ` ( ${bmiMessage} ) ` } </span> 
                    </p> 
                </div> 
            )}
      </div>
      
      {/* <iframe src="https://bmicalculatorusa.com/widgets-usa/widget.php?t=550x480" width="550" height="480" frameborder="0"></iframe> */}
{/* 
      <iframe src="" frameborder="0" className='w-100'> <script type="text/javascript" id="WolframAlphaScript6fd0e0394a4d36e187c8e3673bbb1c1d" src="//www.wolframalpha.com/widget/widget.jsp?id=6fd0e0394a4d36e187c8e3673bbb1c1d"></script> </iframe> */}


      </>
    ); 
} 
  
export default BMI2;