import React from "react"
import {Instagram, Facebook, TelephoneFill,EnvelopeFill,GeoAltFill, Whatsapp} from 'react-bootstrap-icons';
import InquiryForm from '../InquiryForm'

const ContactSection = (props) =>{
    return (
    <>
    <section className='pt-5 mt-lg-5'/>

    <section className='container-fluid pe-md-5'>
                <div className=''>
                <div className="">
                    <div className='row justify-content-center text-center'>
                        <div className='col-11 col-md-8 col-lg-4 mb-3 border-end border-2'>
                            <div className='my-2'>
                                <TelephoneFill className='text-ss-primary' size={50} />
                                <div className='fs-ss-26 mt-3 mb-md-3'>
                                    <div className='fw-600' >Phone</div>
                                </div>
                            </div>
                            <div className='mb-2'>
                            <a href="tel:+16319978135" target='_blank' className='text-decoration-none text-ss-secondary fw-500 fs-ss-18'>
                              631-997-8135
                            </a>
                            </div>
                            <div className=''>
                            <a href="tel:+16317245300" target='_blank' className='text-decoration-none text-ss-secondary fw-500 fs-ss-18'>
                              631-724-5300
                            </a>
                            </div>
                            
                        </div>
                        <div className='col-11 col-md-8 col-lg-4 mb-3 border-end border-2'>
                            <div className='my-2'>
                                <EnvelopeFill className='text-ss-primary' size={50} />
                                <div className='fs-ss-26 mt-3 mb-md-3'>
                                    <div className='fw-600' >Email</div>
                                </div>
                            </div>
                            <div className='my-2'>
                            <a href="mailto:weightmanagement@drforohar.com" target='_blank' className='text-decoration-none text-ss-secondary fw-500 fs-ss-18'>
            <span className=""> weightmanagement@drforohar.com </span>
            </a>
                            </div>
                            
                        </div>

                        <div className='col-11 col-md-8 col-lg-4 mb-3'>
                            <div className='my-2'>
                                <GeoAltFill className='text-ss-primary' size={50} />
                                <div className='fs-ss-26 mt-3 mb-md-3'>
                                    <div className='fw-600' >Address</div>
                                </div>
                            </div>
                            <div className='my-2 px-md-4'>
                            <a href="https://maps.app.goo.gl/FEjmEcx22Gw3xsvF6" target='_blank' className='text-decoration-none text-ss-secondary fw-500 fs-ss-18'>
            50 Route 111 Suite 302 Smithtown, <br className='d-none d-lg-block'/> 
            <span className='ms-lg-4'> New York 11787 </span> 
            </a>
                            </div>
                            
                        </div>
                    </div>
                </div>
                </div>
            </section>

            <section className='pt-5 mt-lg-5'/>

            <section className="container-fluid">
                <div className="row font-pri justify-content-center gy-5">             
                <div className="col-11 col-md-8 col-lg-5 pe-md-5 text-center text-md-start">
                    {/* <img className="w-100" src={props.Mobile} alt="" /> */}
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12071.410090229168!2d-73.188156!3d40.8531616!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e83a193a1b0cf5%3A0x5b018789218a786!2sNorth%20Shore%20Digestive%20Medicine%2C%20Pc!5e0!3m2!1sen!2s!4v1713440610627!5m2!1sen!2s" height="100%" allowfullscreen="" loading="lazy" className='w-100 shadow-ss-inner border-bottom-grey rounded-3' referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div className="col-11 col-md-8 col-lg-5 border-start border-2 ps-lg-5">
                <div className="bg-primary px-lg-5 py-5 px-4 rounded-3">
          <div className='px-lg-2'>
            <div className='fs-ss-30 fw-600 lh-sm text-ss-secondary text-uppercase text-center'>
              Working Hours
            </div>
            <div className='pt-4'/>
            <div className='fs-ss-16 fw-500 lh-sm'>
            <table className="table">
              <tbody>
                <tr>
                  <td> Mon: </td>
                  <td className='text-end'> 8:00 AM - 4:30 PM </td>
                </tr>

                <tr>
                  <td> Tues: </td>
                  <td className='text-end'> 8:00 AM - 3:00 PM </td>
                </tr>

                <tr>
                  <td> Wed: </td>
                  <td className='text-end'> 8:00 AM - 3:00 PM </td>
                </tr>

                <tr>
                  <td> Thur: </td>
                  <td className='text-end'> 8:00 AM - 3:00 PM </td>
                </tr>

                <tr>
                  <td> Fri: </td>
                  <td className='text-end'> 9:00 AM - 2:00 PM </td>
                </tr>

                <tr>
                  <td> Sat - Sun </td>
                  <td className='text-end'> Closed </td>
                </tr>
              </tbody>
            </table>
            </div>
          </div>
        </div>
        <div className="ps-2">
        <h3 className="mt-4 fs-ss-22 fw-600 text-ss-secondary">
            Follow Us
            </h3>
            <div className="mt-3" >
            <a className='text-decoration-none text-ss-secondary fw-500' target='_blank' href="https://www.facebook.com/northshoredigestivemedicine/"> <Facebook className='text-ss-secondary me-2 px-2 fs-ss-40 border-social rounded-5'/> </a>
            <a className='text-decoration-none text-ss-secondary fw-500' target='_blank' href="https://www.instagram.com/northshoredigestivemedicine/"> <Instagram className='text-ss-secondary me-2 px-2 fs-ss-40 border-social rounded-5'/> </a>
            </div>
        </div>
        </div>
        </div>
            </section>

            <section className='pt-5 mt-lg-5'/>

            <section className="bg-orange-pattern">
                <InquiryForm/>
            </section>

{/* 
           <div className="container-fluid px-0">
           <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12071.410090229168!2d-73.188156!3d40.8531616!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e83a193a1b0cf5%3A0x5b018789218a786!2sNorth%20Shore%20Digestive%20Medicine%2C%20Pc!5e0!3m2!1sen!2s!4v1713440610627!5m2!1sen!2s" height="410" allowfullscreen="" loading="lazy" className='w-100 shadow-ss-inner border-bottom-grey' referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div> */}
    </>
    )
}

export default ContactSection;