import React from 'react';
import {Link} from 'react-router-dom';

const BenefitsofESG = (props) => {
  return (
    <>
    <div id='landing-page-font' className="d-flex text-start align-items-center pb-5">
      <div className="me-4">
      <img className='w-icons-landing-page' src={props.Image} alt={props.Alt} />
      </div>
      <div className="">
      <div className='fw-600 fs-ss-22 lh-sm'>
       {/* <span className='hr-underline'> {props.First_Word} </span>  */}
       {props.Title}
      </div>
      {/* <div className='fw-500 fs-ss-15 pt-0'>
        {props.Para}
      </div> */}
      </div>
    </div>
    </>
  )
}

export default BenefitsofESG