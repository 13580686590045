import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import Img1 from '../../assets/images/capsovision-endoscopy/Img1.webp';
import MiddleBannerImage from '../../assets/images/capsovision-endoscopy/MiddleBg.webp';
import { Link } from 'react-router-dom';
import FAQsSection from '../../components/FAQsSection';
import CommonBanner from '../../components/CommonBanner';
import BannerImg from '../../assets/images/capsovision-endoscopy/BannerImg.webp';
import { CircleFill } from 'react-bootstrap-icons';
import Bullets from '../../components/common/Bullets';
import { MetaTags } from 'react-meta-tags';

const BreathTests = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
      <MetaTags>
        <title> Achieve Optimal Digestive Health with Capsovision Endoscopy </title> 
        <meta name="description" content="Transform your digestive health with Capsovision Endoscopy. Schedule your appointment now for personalized care and effective treatment" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Achieve Optimal Digestive Health with Capsovision Endoscopy" />
        <meta property="og:description" content="Transform your digestive health with Capsovision Endoscopy. Schedule your appointment now for personalized care and effective treatment" />
        <meta property="og:url" content="https://northshoredigestivemedicinepc.com/" />
        <meta property="og:site_name" content="NSDM" />
        <meta property="og:image" content="https://northshoredigestivemedicinepc.com/og.webp" />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
        <meta property="og:image:secure_url" content="https://northshoredigestivemedicinepc.com/og.webp" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content="Transform your digestive health with Capsovision Endoscopy. Schedule your appointment now for personalized care and effective treatment" />
        <meta name="twitter:title" content="Achieve Optimal Digestive Health with Capsovision Endoscopy" />
        <meta name="twitter:image" content="https://northshoredigestivemedicinepc.com/og.webp"/>
      </MetaTags>
    <Header/>

    <CommonBanner
    Title="CapsoVision Endoscopy"
    Image={BannerImg}
    />
    
    <section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary text-lg-center">
      <div className="row justify-content-center">
        <div className="col-11">
          <div className=''>
            <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            CapsoVision Endoscopy 
            </h2>
            <h3 className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm pt-2"> CapsoVision Endoscopy for Digestive Disorders
            </h3>
            <div className='pt-4'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            CapsoVision capsule endoscopy platform incorporates cutting-edge technology to deliver clear gastrointestinal (GI) tract images. It targets specific parts of the GI tract, such as the stomach, small bowel, colon, or esophagus; each CapsoVision system offers unparalleled insight. 
            This innovative imaging system enables physicians to detect abnormalities and diseases precisely, facilitating accurate treatment. With CapsoVision, medical professionals can provide targeted interventions, improving patient outcomes.
            <div className='py-2'/> 
            Moreover, the CapsoVision system is noninvasive, making it incredibly convenient for patients. Simply ingesting the CapsoVision capsule with water initiates imaging, eliminating the need for traditional invasive procedures.
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>
    
    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-evenly align-items-center gy-md-4 ">
        <div className="col-11 col-lg-6">
          <div className=''>
            <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            CapspoVision Endoscopy at <br className='' /> North Shore Digestive Medicine
            </h2>
            <div className='pt-4'/>
            At North Shore Digestive Medicine, we are dedicated to providing advanced medical technologies and personalized care to our patients. Our experienced team of healthcare professionals utilizes innovative tools like CapsoVision capsule endoscopy to ensure accurate diagnosis and effective treatment of gastrointestinal conditions.
            <div className='py-2'/>
            Schedule an appointment with us today and experience the difference our compassionate care and cutting-edge technologies can make in your journey to better health. Your path to wellness starts here.
          </div>
        </div>
        <div className="col-10 col-lg-5">
        <img className='w-100 px-lg-5' src={Img1} alt="" />        
        </div>
      </div>
    </section>

    <section className='pt-md-5 pt-3 mt-lg-5'/>

    <section id='website-responsive' className="container-fluid bg-digestive bg-digestive5 py-5 row justify-content- gx-0">
    <section className="container-lg text-ss-secondary text-lg-center py-5">
      <div className="row justify-content-center">
        <div className="col-11">
          <div className=''>
            <h2 className='fs-ss-40 fw-600 lh-sm text-black'>
            Experience Advanced Imaging <br/>with CapsoVision
            </h2>
            <div className="pt-4">
            <div className='fs-ss-18 fw-500 lh-para'>
            If you'd like to learn more about procedures or to <br className='d-none d-md-block'/> find out if you are a fit candidate or not, please contact us!
            </div>
            <div className='pt-4 pt-lg-5'/>
          <Link to="/book-an-appointment" className='text-decoration-none py-md-2 py-1 fw-500 btn-transparent-dark text-ss-secondary rounded-5 fs-ss-16 px-3 border-dark border-1 border'>
            Book an Appointment
          </Link>          
          </div>
            </div>
          </div>
        </div>   
    </section>
    </section>

    <section className='pt-5 mt-lg-4'/>

    <section id='website-responsive' className="container-lg text-ss-secondary text-lg-center">
      <div className="row justify-content-center">
        <div className="col-11">
          <div className=''>
            <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            Ready to Take Control of <br className='d-none d-md-block'/> Your Digestive Health? 
            </h2>
            <div className='pt-4'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            Don't let ongoing digestive complaints hold you back. Schedule an appointment with North Shore Digestive Medicine today and take the first step towards finding relief and improving your quality of life. Contact us to book your appointment and start your journey to digestive wellness. 
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-4'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
    <div className='fs-ss-30 fw-600 fw-600 lh-sm text-center pb-4'>
      FAQS
      </div>
      <div className="row justify-content-center text-center">
        <div className="col-11 col-lg-11">
          <FAQsSection
          Title="What is CapsoVision capsule endoscopy? "
          Para="CapsoVision capsule endoscopy is a noninvasive imaging technology that allows a detailed gastrointestinal tract examination. Patients swallow a small capsule containing a camera, which captures images as they pass through the digestive system."
          />

          <FAQsSection
          Title="How does CapsoVision capsule endoscopy work? "
          Para="Once ingested, the CapsoVision capsule travels through the GI tract, capturing images. The capsule is then retrieved, and images are downloaded to a computer for the doctor to review. "
          />

          <FAQsSection
          Title="Is CapsoVision capsule endoscopy painful? "
          Para="No, CapsoVision capsule endoscopy is a noninvasive procedure that doesn't cause any discomfort or pain. Patients swallow the capsule with water, and the imaging process occurs naturally as the capsule moves through the digestive system."
          />

          <FAQsSection
          Title="What conditions can CapsoVision capsule endoscopy detect? "
          Para="CapsoVision capsule endoscopy can help detect various gastrointestinal conditions, including inflammatory bowel disease, small bowel tumors, bleeding sources, and abnormalities in the esophagus, stomach, or colon."
          />

          <FAQsSection
          Title="How long does it take to receive results from CapsoVision capsule endoscopy? "
          Para="The time it takes to receive results from CapsoVision capsule endoscopy may vary depending on the healthcare provider's review process. In general, patients can expect to receive results within a few days to a week after the procedure."
          />

        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>

    <Footer/>
    </>
  );
}

export default BreathTests;
