import React from 'react';
import { CircleFill } from 'react-bootstrap-icons';
import {Link} from 'react-router-dom';

const Bullets = (props) => {
  return (
    <>
    <div className='d-flex align-items-start fs-ss-18 fw-500 lh-para pb-md-3 pb-1'> 
      <div id='bullets' className="">
      <CircleFill className='me-2 fs-ss-12 text-ss-primary mb-2'/>
      </div>
      <div className='px-1' />
      <div className="">
        {props.Title}
        <div className="fs-ss-15 fw-400 lh-sm">
        {props.Para}
        </div>
      </div>
    </div>
    </>
  )
}

export default Bullets