import React from 'react';
import {Link} from 'react-router-dom';
import FooterLogo from '../../assets/images/home/HeaderLogo.webp';
import { ArrowRightCircleFill, EnvelopeFill, Facebook, GeoAltFill, Instagram, TelephoneFill, Twitter } from 'react-bootstrap-icons';

const Footer = () => {
  return (
    <>
    <section className="container-fluid bg-light pb-lg-5 pb-4 ">
      <div className="row justify-content-evenly pt-4 gy-4 pt-lg-5">
        <div className="col-8 col-lg-3 text-lg-center">
          <div className=''>
          <img src={FooterLogo} alt="" className="mx-lg-auto w-100 mb-md-3" />
            <div className='text-center py-3 pt-xl-4 d-none d-lg-block'>
            <a className='text-decoration-none text-ss-secondary fw-500' target='_blank' href="https://www.facebook.com/northshoredigestivemedicine/"> <Facebook className='text-ss-secondary me-2 px-2 fs-ss-40 border-social rounded-5'/> </a>
            <a className='text-decoration-none text-ss-secondary fw-500' target='_blank' href="https://www.instagram.com/northshoredigestivemedicine/"> <Instagram className='text-ss-secondary me-2 px-2 fs-ss-40 border-social rounded-5'/> </a>
            </div>            
            </div>
        </div>
        <div id='website-responsive' className="col-11 col-lg-2">
          <div className='text-ss-secondary mb-2 mb-xl-4 fw-500 fs-ss-26-links lh-sm'>
            Useful Links
            <hr className='hr-footer' />
          </div>
          <div className='mb-2'>           
            <Link to="/about-us" className='text-decoration-none text-ss-secondary fw-500 fs-ss-18'>
              About Us
            </Link> 
          </div>
          <div className='mb-2'>  
            <Link to="/contact-us" className='text-decoration-none text-ss-secondary fw-500 fs-ss-18'>
            Contact Us
            </Link> 
          </div>
          <div className='mb-2'>
             <Link to="/success-stories" className='text-decoration-none text-ss-secondary fw-500 fs-ss-18'>
             Success Stories
             </Link> 
           </div>
           <div className='mb-2'>
             <Link to="/privacy-policy" className='text-decoration-none text-ss-secondary fw-500 fs-ss-18'>
             Privacy Policy 
             </Link> 
           </div>
        </div>
        <div id='website-responsive' className="col-11 col-lg-4">
          <div className='text-ss-secondary mb-2 mb-xl-4 fw-500 fs-ss-26-links lh-sm'>
            Contact Us
            <hr className='hr-footer' />
          </div>
          <div className='mb-2'>
            <TelephoneFill className='text-ss-secondary me-2'/> 
            <a href="tel:+16317245300" target='_blank' className='text-decoration-none text-ss-secondary fw-500 fs-ss-18'>
              631-724-5300
            </a>
          </div>
          <div className='mb-2'>
            <TelephoneFill className='text-ss-secondary me-2'/> 
            <a href="tel:+16319978135" target='_blank' className='text-decoration-none text-ss-secondary fw-500 fs-ss-18'>
            631-997-8135
            </a>
          </div>
          <div className='mb-2'>
            <EnvelopeFill className='text-ss-secondary me-2'/> 
            <a href="mailto:weightmanagement@drforohar.com" target='_blank' className='text-decoration-none text-ss-secondary fw-500 fs-ss-18'>
            <span className=""> weightmanagement@drforohar.com </span>
            </a>
          </div>
          <div className=''>
            <GeoAltFill className='text-ss-secondary me-2'/> 
            <a href="https://maps.app.goo.gl/FEjmEcx22Gw3xsvF6" target='_blank' className='text-decoration-none text-ss-secondary fw-500 fs-ss-18'>
            50 Route 111 Suite 302 Smithtown, <br className='d-none d-lg-block'/> 
            <span className='ms-lg-4'> New York 11787 </span> 
            </a>
          </div>
        </div>
        <div className='pt-xl-4 col-11 d-block d-lg-none'>
            <a className='text-decoration-none text-ss-secondary fw-500' target='_blank' href="https://www.facebook.com/northshoredigestivemedicine/"> <Facebook className='text-ss-secondary me-2 px-2 fs-ss-40 border-social rounded-5'/> </a>
            <a className='text-decoration-none text-ss-secondary fw-500' target='_blank' href="https://www.instagram.com/northshoredigestivemedicine/"> <Instagram className='text-ss-secondary me-2 px-2 fs-ss-40 border-social rounded-5'/> </a>
            </div>  
      </div> 
    </section>
    <section className="container-fluid py-2 bg-white fs-ss-14 text-black text-center">
      Copyright © 2024 North Shore Digestive Medicine. <br className='d-block d-md-none' /> Powered by <a className='text-ss-primary fw-500' href="https://simplesolutionz.org/" target='_blank'> Simple Solutionz </a>
    </section>
    </>
  );
}

export default Footer;
