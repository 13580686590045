import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BannerImg from '../assets/images/RealPatient.webp';
import CommonBanner from '../components/CommonBanner';
import { Link } from 'react-router-dom';
import Img1 from '../assets/images/real-stories/6.webp';
import Img2 from '../assets/images/real-stories/5.webp';
import Img3 from '../assets/images/real-stories/4.webp';
import Img4 from '../assets/images/real-stories/3.webp';
import Img5 from '../assets/images/real-stories/1.webp';
import Img6 from '../assets/images/real-stories/2.webp';
import { TelephoneFill } from 'react-bootstrap-icons';
import MiddleBannerImage from '../assets/images/real-stories/Img1.webp';

const SuccessStories = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
      <title>
        Success Stories - North Shore Digestive Medicine
      </title>
    </Helmet>
    <Header/>

    <CommonBanner
    Title={<> Success <br className="d-block d-md-none" /> Stories </>}
    Image={BannerImg}
    />

    <section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-evenly align-items-center gy-4 ">
      <div className="col-12 col-md-5 me-md-auto d-block d-md-none">
      <img className='w-100' src={Img1} alt="" />        
        </div>
        <div className="col-11 col-lg-6">
          <div className=''>
            <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            LINDSAY LOST 54 LBS* </h2>
            <h3 className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm pt-2"> “If I felt like this earlier, I can’t imagine what my life would have been like.” </h3>
            <div className='pt-3 pt-md-4'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            Fad diets, bootcamps, Weight Watchers, Medical Weight Loss, and even Hypnotherapy - Lindsay had tried them all. Needless to say, none of them worked for her. But when she learned about Orbera®, she knew she had to do it. After losing 54 pounds, her Princess Party business took off, and so did her self esteem.
            </div>
          </div>
        </div>
        <div className="col-12 col-md-5 ms-md-auto d-none d-md-block">
        <img className='w-100' src={Img1} alt="" />        
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-2'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-evenly align-items-center gy-4 ">
      <div className="col-12 col-md-5 me-md-auto">
      <img className='w-100' src={Img2} alt="" />        
        </div>
        <div className="col-11 col-lg-6">
          <div className=''>
            <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            SHAWN LOST 126 LBS* </h2>
            <h3 className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm pt-2"> “This is the cheapest new life I’ve could have ever bought. I’ve got another 40 years with my family.” </h3>
            <div className='pt-3 pt-md-4'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            At his heaviest, Shawn was 350 pounds. Even with so much to be proud of as a military veteran and successful business owner - Shawn didn’t like the guy he saw in the mirror. With Orbera® he lost 126 pounds and took his life from a sad state, to exploring new states while competing in Ironman competitions.
            </div>
          </div>
          </div>
      </div>
    </section>
    
    <section className='pt-5 mt-lg-2'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-evenly align-items-center gy-4 ">
      <div className="col-12 col-md-5 me-md-auto d-block d-md-none">
      <img className='w-100' src={Img3} alt="" />        
        </div>
        <div className="col-11 col-lg-6">
          <div className=''>
            <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            CLARA LOST 70 LBS* </h2>
            <h3 className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm pt-2"> “Orbera® saved my life!” </h3>
            <div className='pt-3 pt-md-4'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            With the scale slowly approaching 300 lbs, Clara knew she needed to make her health a priority. With a lot of hard work and determination, she now leads a healthy lifestyle and is extremely active. After losing 70 lbs with the Orbera® gastric balloon, Clara is now a role model for her entire family.
            </div>
          </div>
        </div>
        <div className="col-12 col-md-5 ms-md-auto d-none d-md-block">
        <img className='w-100' src={Img3} alt="" />        
        </div>
      </div>
      </section>

      <section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className={`container-fluid bg-middle-banner2 py-5`} style={{backgroundImage:`url(${MiddleBannerImage})`}}>
      <div className="row justify-content-center text-center">
        <div className="col-11 col-md-8 py-md-5">
          <div className='fw-700 text-white fs-ss-middle-banner2 text-uppercase lh-sm'>
            Empowering You to Reach Your Health Goals, Together
          </div>
          <div id='btn' className="pt-4 pt-lg-5">
          <Link to="/contact-us" className='text-decoration-none py-2 fw-500 btn-middle-banner2 rounded-5 fs-ss-18 px-4 border-white border-1 border'>
            Contact Us
          </Link>          
          </div>
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-evenly align-items-center gy-4 ">
      <div className="col-12 col-md-5 me-md-auto">
      <img className='w-100' src={Img4} alt="" />        
        </div>
        <div className="col-11 col-lg-6">
          <div className=''>
            <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            STEPHANIE LOST 50 LBS* </h2>
            <h3 className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm pt-2"> “I no longer have aches and pains, my overall health and wellbeing are better. I keep wondering why I didn’t do this sooner” </h3>
            <div className='pt-3 pt-md-4'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            For Stephanie, Orbera® finally put an end to the rollercoaster of losing weight and then gaining it right back. After spending tens of thousands on diets, gym memberships, and all the sizes of clothing in between… She finally learned about Orbera®. At age 50, Stephanie lost 50 pounds. Now, a year later, she’s not only keeping off, but enjoying a new and more confident life in New York City.
            </div>
          </div>
          </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-2'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-evenly align-items-center gy-4 ">
      <div className="col-12 col-md-5 me-md-auto d-block d-md-none">
      <img className='w-100' src={Img5} alt="" />        
        </div>
      <div className="col-11 col-lg-6">
          <div className=''>
            <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            MARY LOST 92 LBS* </h2>
            <h3 className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm pt-2"> "Orbera® has given me the strength that I needed to make a change. It’s given me the confidence to believe in myself once again." </h3>
            <div className='pt-3 pt-md-4'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            After stepping on the scale and seeing that she weighed in at over 230 lbs, she knew it was time for a change. Orbera®'s non-surgical and minimally-invasive approach was perfect for her. Mary has lost 92 lbs and has completely transformed her life, with help from Orbera®.
            </div>
          </div>
          </div>
          <div className="col-12 col-md-5 ms-md-auto d-none d-md-block">
        <img className='w-100' src={Img5} alt="" />        
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-2'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">    
    <div className="row justify-content-evenly align-items-center gy-4 ">
    <div className="col-12 col-md-5 me-md-auto">
      <img className='w-100' src={Img6} alt="" />        
        </div>
        <div className="col-11 col-lg-6">
          <div className=''>
            <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            CHARLOTTE LOST 43 LBS* </h2>
            <h3 className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm pt-2"> “My new boyfriend recently told me I don’t eat very much, that’s not something that would happen to the old me.” </h3>
            <div className='pt-3 pt-md-4'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            Charlotte was tired of not feeling confident on dates, and not being active. Orbera® helped her get control of her eating habits and, in losing 43 pounds, the feeling of success has snowballed - Now she’s addicted to yoga. And when it comes to dating, it’s a whole new experience.
            </div>
          </div>
          </div>
      </div>
      </section>

    <section className='pt-5 mt-lg-5'/>

    <Footer/>
    </>
  );
}

export default SuccessStories;
