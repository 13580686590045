import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import Img1 from '../../assets/images/colonoscopy/Img1.webp';
import MiddleBannerImage from '../../assets/images/colonoscopy/MiddleBg.webp';
import { Link } from 'react-router-dom';
import FAQsSection from '../../components/FAQsSection';
import CommonBanner from '../../components/CommonBanner';
import BannerImg from '../../assets/images/colonoscopy/BannerImg.webp';
import { CircleFill } from 'react-bootstrap-icons';
import Bullets from '../../components/common/Bullets';
import { MetaTags } from 'react-meta-tags';

const BreathTests = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
      <MetaTags>
        <title> Colonoscopy Procedure - Discover a Safe Digestive Solution ! </title> 
        <meta name="description" content="Ensure your digestive health with our professional colonoscopy procedure. Convenient appointments, expert care, and advanced diagnostics. " />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Colonoscopy Procedure - Discover a Safe Digestive Solution !" />
        <meta property="og:description" content="Ensure your digestive health with our professional colonoscopy procedure. Convenient appointments, expert care, and advanced diagnostics. " />
        <meta property="og:url" content="https://northshoredigestivemedicinepc.com/" />
        <meta property="og:site_name" content="NSDM" />
        <meta property="og:image" content="https://northshoredigestivemedicinepc.com/og.webp" />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
        <meta property="og:image:secure_url" content="https://northshoredigestivemedicinepc.com/og.webp" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content="Ensure your digestive health with our professional colonoscopy procedure. Convenient appointments, expert care, and advanced diagnostics. " />
        <meta name="twitter:title" content="Colonoscopy Procedure - Discover a Safe Digestive Solution !" />
        <meta name="twitter:image" content="https://northshoredigestivemedicinepc.com/og.webp"/>
      </MetaTags>
    <Header/>

    <CommonBanner
    Title="Colonoscopy"
    Image={BannerImg}
    />
    
    <section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary text-lg-center">
      <div className="row justify-content-center">
        <div className="col-11">
          <div className=''>
            <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            Colonoscopy: Ensuring Colorectal Health
            </h2>
            <h3 className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm pt-2"> Colonoscopy for Digestive Disorders  </h3>
            <div className='pt-4'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            A colonoscopy is a pivotal medical procedure facilitating a thorough examination of the large intestine. It is a crucial method for early detection and prevention of colorectal ailments, notably cancer.
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>
    
    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-evenly align-items-center gy-md-4">
        <div className="col-11 col-lg-6">
          <div className=''>
          <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            Why and When to Opt <br/>for a Colonoscopy?
            </h2>
            <div className='pt-4'/>
            <div className='fs-ss-18 fw-500 lh-para'>
            <Bullets
            Title="Screening Guidelines"
            Para="Guidelines advocate a screening colonoscopy at 45 for individuals with average risk, serving as a baseline examination."
            />
            <Bullets
            Title="Family History"
            Para="Individuals with a family history of colon diseases, such as colorectal cancer or polyps, may benefit from undergoing a colonoscopy at an earlier age. Consult a healthcare provider to determine the suitable timing based on your risk factors."
            />
            <Bullets
            Title="Comprehensive Examination"
            Para="Unlike alternative screening methods, a colonoscopy allows direct and thorough examination of the entire colon, enabling precise detection and removal of precancerous polyps or early-stage cancers."
            />
            <Bullets
            Title="Preventive Intervention"
            Para="Eliminating precancerous polyps during a colonoscopy substantially diminishes the risk of developing colorectal cancer, rendering it a potent preventive measure."
            />
            <Bullets
            Title="Schedule Your Colonoscopy"
            Para="Take proactive measures to safeguard your health by scheduling a colonoscopy with your healthcare provider without waiting for symptoms to emerge."
            />  

            </div>
          </div>
        </div>
        <div className="col-10 col-lg-5">
        <img className='w-100 px-lg-5' src={Img1} alt="" />        
        </div>
      </div>
    </section>

    <section className='pt-md-5 pt-3 mt-lg-5'/>

    <section id='website-responsive' className="container-fluid bg-digestive bg-digestive1 py-5 row justify-content-center gx-0">
    <section className="container-lg text-ss-secondary text-lg-center py-5">
      <div className="row justify-content-center">
        <div className="col-11">
          <div className=''>
            <h2 className='fs-ss-40 fw-600 lh-sm text-black'>
            Schedule Your Colonoscopy Today
            </h2>
            <div className="pt-4 pt-lg-5">
            <div className='fs-ss-18 fw-500 lh-para'>
            If you'd like to learn more about procedures or to <br className='d-none d-md-block'/> find out if you are a fit candidate or not, please contact us!
            </div>
            <div className='pt-4 pt-lg-5'/>
          <Link to="/book-an-appointment" className='text-decoration-none py-md-2 py-1 fw-500 btn-transparent-dark text-ss-secondary rounded-5 fs-ss-16 px-3 border-dark border-1 border'>
            Book an Appointment
          </Link>          
          </div>
            </div>
          </div>
        </div>   
    </section>
    </section>

    <section className='pt-5 mt-lg-4'/>

    <section id='website-responsive' className="container-lg text-ss-secondary text-lg-center">
      <div className="row justify-content-center">
        <div className="col-11">
          <div className=''>
            <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            Colonoscopy at North Shore <br className='d-none d-md-block'/> Digestive Medicine  
            </h2>
            <div className='pt-4'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            Do not let fear or uncertainty impede prioritizing your colorectal health. Initiate early detection and prevention by scheduling your colonoscopy today. Your future well-being will reap the benefits. Whether you need preventive care or treatment for a specific condition or seek guidance on improving your overall health, North Shore Digestive Medicine is here to help you every step. Schedule an appointment with us today and experience the difference personalized care can make.
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-4'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
    <div className='fs-ss-30 fw-600 fw-600 lh-sm text-center pb-4'>
      FAQS
      </div>
      <div className="row justify-content-center text-center">
        <div className="col-11 col-lg-11">
          <FAQsSection
          Title="Is colonoscopy painful?"
          Para="Most patients undergo sedation during a colonoscopy, minimizing discomfort. While some may experience mild discomfort or cramping, it's generally well-tolerated."
          />

          <FAQsSection
          Title="How should I prepare for a colonoscopy?"
          Para="Preparation typically involves following a clear liquid diet and taking prescribed laxatives to empty the colon. Your healthcare provider will provide practical instructions."
          />

          <FAQsSection
          Title="Are there any risks associated with colonoscopy?"
          Para="Although generally safe, risks include bleeding, colon perforation, and adverse reactions to sedation. However, these are rare compared to the benefits."
          />

          <FAQsSection
          Title="How often should I have a colonoscopy?"
          Para="Frequency depends on individual risk factors. Screening every 10 years from age 45 is typical for those at average risk. However, personalized recommendations are based on personal circumstances."
          />

        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>

    <Footer/>
    </>
  );
}

export default BreathTests;
