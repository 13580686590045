import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BannerImg from '../assets/images/FAQs.webp';
import CommonBanner from '../components/CommonBanner';
import FAQsSection from '../components/FAQsSection';

const FAQs = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
      <title>
      FAQs - North Shore Digestive Medicine
      </title>
    </Helmet>
    <Header/>

    <CommonBanner
    Title="FAQs"
    Image={BannerImg}
    />
    <section className='pt-5 pt-lg-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
    <div className='fs-ss-30 fw-600 fw-600 lh-sm text-center pb-4'>
      FAQS
      </div>
      <div className="row justify-content-center text-center">
        <div className="col-11 col-lg-11">
        <FAQsSection
          Title="What is the best non-surgical weight loss treatment? "
          Para={<> Non-surgical weight loss treatments vary depending on individual needs and health goals. Options include lifestyle modifications, dietary changes, exercise programs, behavior therapy, prescription medications, and non-invasive procedures like endoscopic sleeve gastroplasty (ESG), or Orbera Balloon. 
          </>}
          />

          <FAQsSection
          Title="What types of weight loss procedures in Long Island does North Shore Digestive Medicine offer? "
          Para={<> We specialize in advanced, non-surgical weight loss procedures such as Endoscopic Sleeve Gastroplasty (ESG) and Orbera Balloon in Long Island, New York.     
          </>}
          />

          <FAQsSection
          Title="How does Endoscopic Sleeve Gastroplasty (ESG) work for weight loss? "
          Para="ESG reduces stomach size using an endoscope without incisions, limiting food intake, and encouraging weight loss. Furthermore, patients typically achieve a weight loss of 15-20% of their excess body weight within the first year. "
          />

          <FAQsSection
          Title="What is a Surgical Weight Loss Program? "
          Para="A Surgical Weight Loss Program, or bariatric surgery, is a medical procedure designed to help individuals who struggle with severe obesity achieve significant weight loss. It involves various surgical techniques, such as gastric bypass or sleeve gastrectomy, to reduce the size of the stomach and alter the digestive process, leading to weight loss and improvement in related health conditions. "
          />

          <FAQsSection
          Title="What is a Colonoscopy? "
          Para="A Colonoscopy is a procedure used to examine the large intestine (colon) and rectum for abnormalities such as polyps, inflammation, or cancer. During the procedure, a long, flexible tube with a camera (colonoscope) is inserted through the rectum into the colon. It allows for visualization of the colon's lining and enables the removal of polyps or tissue samples for further testing. "
          />   
          
          <FAQsSection
          Title="What is an Upper Endoscopy (EGD)? "
          Para="An Upper Endoscopy, also known as Esophagogastroduodenoscopy (EGD), is a procedure used to examine the esophagus, stomach, and the beginning of the small intestine (duodenum). It involves inserting a flexible tube with a camera (endoscope) through the mouth and into the upper digestive tract. Upper Endoscopy is commonly used to diagnose conditions such as ulcers, inflammation, or tumors in the upper gastrointestinal tract. "
          />

          <FAQsSection
          Title="What is the safest weight loss procedure? "
          Para={<> The safety of a weight loss procedure depends on numerous factors, including the patient's medical history, risk factors, and the healthcare provider's expertise. Generally, non-surgical procedures such as gastric balloon placement or ESG are considered safer alternatives to invasive surgeries like gastric bypass or sleeve gastrectomy.  
          </>}
          />
          
          <FAQsSection
          Title="What is the best non-surgical fat removal for belly fat? "
          Para={<> Non-surgical fat removal options for belly fat include procedures like CoolSculpting (cryolipolysis), laser liposuction, radiofrequency (RF) treatments, and gastric balloon placement. These methods target and eliminate stubborn fat cells without surgery, offering effective results with minimal downtime. 
          </>}
          />

          <FAQsSection
          Title="What are Breath Tests used for? "
          Para={<> Breath Tests are non-invasive diagnostic tests used to detect certain gastrointestinal disorders or conditions. These tests involve ingesting a specific substance, typically lactose or a sugar solution, and then measuring the levels of hydrogen or methane gas in the breath over time. Breath tests commonly diagnose conditions such as lactose intolerance, bacterial overgrowth in the small intestine, or Helicobacter pylori infection.  
          </>}
          />

          <FAQsSection
          Title="What is ERCP (Endoscopic Retrograde Cholangiopancreatography)?  "
          Para="ERCP is a specialized endoscopic procedure to diagnose and treat bile ducts, pancreas, and gallbladder conditions. It involves passing an endoscope through the mouth, esophagus, stomach, and duodenum. A dye is then injected into the bile and pancreatic ducts, allowing X-ray visualization of these structures. ERCP can remove gallstones, open narrowed bile ducts, or place stents to relieve obstructions. "
          />
          
          <FAQsSection
          Title="What is the Orbera Balloon procedure, and what are its benefits? "
          Para=" The Orbera Balloon involves placing a soft silicone balloon in the stomach to control portion sizes and promote fullness. Patients usually lose 10-15% of their body weight within six months without surgery. So, if you are searching for a 'Bariatric nutritionist near me in Long Island, New York,' you can contact North Shore Digestive Medicine. "
          />

          <FAQsSection
          Title=" How can I shrink my stomach without surgery? "
          Para={<>
          Non-surgical methods to shrink the stomach include: 
          <div className="py-2" />
          <ul>
          <li> Adopting a healthy diet with smaller portions. </li>
          <li> Increasing physical activity. </li>
          <li> Practicing mindful eating habits. </li>
          <li> Seeking support from a healthcare professional or registered dietitian. </li>
          </ul>
          <div className="py-2" />
          Additionally, gastric balloon placement or ESG can temporarily reduce stomach capacity. 
          </>}
          />

          <FAQsSection
          Title="What is the 15-minute weight loss procedure? "
          Para="The 15-minute weight loss procedure likely refers to endoscopic procedures such as ESG or gastric balloon placement, which can be performed relatively quickly with minimal downtime. These procedures aim to reduce stomach capacity and promote weight loss without surgery. "
          />

          <FAQsSection
          Title="How much is a gastric balloon? "
          Para="The cost of a gastric balloon procedure varies depending on the healthcare provider, geographic location, and additional services included in the treatment package—generally, the cost ranges from several thousand to tens of thousands of dollars. "
          />

          <FAQsSection
          Title="Does anyone regret getting a gastric sleeve? "
          Para="While gastric sleeve surgery can lead to significant weight loss and improvement in health for many individuals, it is essential to consider that outcomes and experiences vary among patients. Some individuals may experience complications or dissatisfaction with their results, while others may achieve long-term success and improved quality of life. "
          />

          <FAQsSection
          Title="What is the most effective procedure for belly fat? "
          Para="The most effective procedure for belly fat depends on individual factors such as the amount of fat, skin elasticity, and overall health. Options include non-surgical treatments like CoolSculpting, laser liposuction, RF treatments, and surgical procedures like liposuction or abdominoplasty (tummy tuck). "
          />

          <FAQsSection
          Title="How can skin be tightened after a gastric bypass without surgery? "
          Para={<>
          Non-surgical options to tighten loose skin after gastric bypass include: 
          <div className="py-2" />
          <ul>
          <li> Maintaining a stable weight </li>
          <li> Building muscle through strength training exercises </li>
          <li> Staying hydrated </li>
          <li> Moisturizing the skin </li>
          <li> Considering treatments like RF therapy or ultrasound therapy </li>
          </ul>
          <div className="py-2" />
          However, significant skin tightening may require surgical interventions like body contouring procedures. It's essential to consult a healthcare provider to determine the most appropriate approach for individual needs. 
          </>}
          />       
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5 mt-3'/>

    <Footer/>
    </>
  );
}

export default FAQs;
