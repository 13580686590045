
import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet'

import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import CommonBanner from '../components/CommonBanner';
import ContactSection from '../components/contactus/ContactSection';


// Sources
import BgImage from "../assets/images/contact-us/Banner.webp";

const ContactUs = () => {
  
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  return (
    <>
    <Helmet>
      <title>
      Contact Us - North Shore Digestive Medicine
      </title>
    </Helmet>
    <Header/>
    <CommonBanner
    Title="Contact Us"
    Image={BgImage}
    />

    <ContactSection/>
    <Footer/>
    </>
  );
}

export default ContactUs;


