import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BannerImg from '../assets/images/Finance.webp';
import CommonBanner from '../components/CommonBanner';
import { Link } from 'react-router-dom';

const FinancingAndInsurance = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
      <title>
      Financing and Insurance - North Shore Digestive Medicine
      </title>
    </Helmet>
    <Header/>

    <CommonBanner
    Title="Financing and Insurance"
    Image={BannerImg}
    />

    <section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
          <div className='text-center'>
          <div className='fs-ss-32 fw-600 lh-sm text-uppercase'>
          OUR PAYMENT PLANS <br className='' /> 
            <div className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm pt-2"> Your Non-Surgical Path to Weight Loss Success </div>
            </div>
          </div>
      <div className="row justify-content-center text-center pt-4 pt-lg-5 gy-4">
      <div className="col-serv3 text-center bg-primary text-decoration-none rounded-4 px-4 py-5 shadow-ss-1 me-lg-3">
      <div className=' fw-600 text-center bg-dark border-0 rounded-5 border-0 text-white fs-ss-18 px-2 py-2'>
        ESG
      </div>
      <div className="fs-ss-16 fw-400 text-white lh-sm pt-4 pb-2">
      Payment starts as low as
      </div>
      <div className="fs-ss-35 fw-600 text-white pb-3">
      $297
      </div>
      <div className="fs-ss-16 fw-500 text-white lh-sm">
      Call us at <br />
      <a href="tel:+16319978135" target='_blank' className='text-decoration-none text-ss-secondary fw-600 px-2 '>
        631-997-8135 <br />
      </a>
      for more details
      </div>
      </div>

      <div className="col-serv3 text-decoration-none text-center bg-primary rounded-4 px-4 py-5 shadow-ss-1 me-lg-3">
      <div className=' fw-600 text-center bg-dark border-0 rounded-5 border-0 text-white fs-ss-18 px-2 py-2'>
        Orbera Balloon
      </div>
      <div className="fs-ss-16 fw-400 text-white lh-sm pt-4 pb-2">
      Payment starts as low as
      </div>
      <div className="fs-ss-35 fw-600 text-white pb-3">
      $191
      </div>
      <div className="fs-ss-16 fw-500 text-white lh-sm">
      Call us at <br />
      <a href="tel:+16319978135" target='_blank' className='text-decoration-none text-ss-secondary fw-600 px-2 '>
        631-997-8135 <br />
      </a>
      for more details      
      </div>
      </div>

      <div className="col-serv3 text-decoration-none text-center bg-primary rounded-4 px-4 py-5 shadow-ss-1">
      <div className=' fw-600 text-center bg-dark border-0 rounded-5 border-0 text-white fs-ss-18 px-2 py-2'>
      Gastric Revisions
      </div>
      <div className="fs-ss-16 fw-400 text-white lh-sm pt-4 pb-2">
      Payment starts as low as
      </div>
      <div className="fs-ss-35 fw-600 text-white pb-3">
      $297
      </div>
      <div className="fs-ss-16 fw-500 text-white lh-sm">
      Call us at <br />
      <a href="tel:+16319978135" target='_blank' className='text-decoration-none text-ss-secondary fw-600 px-2 '>
        631-997-8135 <br />
      </a>
      for more details      
      </div>
      </div>

      </div>
    </section>

    <section className='pt-5 mt-lg-5 mt-3'/>

    <Footer/>
    </>
  );
}

export default FinancingAndInsurance;
