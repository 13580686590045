import React from 'react';
import {Link} from 'react-router-dom';

const SingleService = (props) => {
  return (
    <>
    <div className={`col-serv text-center bg-light py-4 shadow-ss-1 rounded-4 ${props.Space} px-4`}>
      <Link className='text-decoration-none text-ss-secondary' to={props.Link_To}>
      <img className='w-services' src={props.Image} alt={props.Alt} />
      <h3 className='fw-600 pt-3 fs-ss-18 lh-sm '>
        {props.Title}
      </h3>
      <div className='fw-500 pt-3 fs-ss-14'>
        {props.Para}
      </div>
      <div className='fw-400 pt-4 fs-ss-16 lh-sm'>
        <span className='text-ss-primary fs-ss-12 border-ss-primary rounded-5 px-2'> Find Out more </span>
      </div>
      </Link>
    </div>
    </>
  )
}

export default SingleService