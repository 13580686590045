import React, {useState, useRef} from "react"
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from 'emailjs-com'
import Img1 from '../assets/images/orbera/Img1.webp';
import { useNavigate } from "react-router-dom";

const InquiryForm = () =>{

  // const Navigatee = useNavigate();

  // const handleSubmit = () => {
  //   Navigatee("/thank-you")
  // }


  const [verified, setVarified] = useState(false)
  function onChange(value) {
    console.log("Captcha value:", value);
    setVarified(true);
  }
  const refform = useRef();
  const sendForm = (e) =>{
    e.preventDefault();
  emailjs.sendForm('service_gmail01','template_northshore',e.target,'2-EU-Dmtl9lxLGg_r').then(res=>{
    console.log(res)
    window.location.href="/thank-you"
  }).catch(err=>{
    console.log(err)
  });
  e.target.reset()
};

    return (
    <>
    <section className="container-fluid px-4 px-lg-5 pb-md-4">
        <div className="row justify-content-center align-items-center py-5 ">
        <div className="col-11 col-lg-7">
                <div className="">
                <div className='fs-ss-30 fw-600 lh-sm text-center text-ss-secondary'>
                  DROP US A LINE
                </div>
                <div className="text-center col-12">
                <form ref={refform} onSubmit={sendForm} id="contactForm" method="POST" action="#" className="px-lg-4">
      <div className="form-group">
        <label className="invisible font-pri fw-600" for="name">Name</label>
        <input type="text" className="form-control py-2" id="name" name="name" placeholder="Name*" required/>
      </div>
      <div className="form-group">
        <label className="invisible font-pri fw-600" for="email">Email</label>
        <input type="email" className="form-control py-2" id="email" name="email" placeholder="Email*" required/>
      </div>
      <div className="form-group">
        <label className="invisible font-pri fw-600" for="phone_number">Phone Number</label>
        <input type="number" className="form-control py-2" id="phone_number" name="phone_number" placeholder="Phone Number*" required/>
      </div>
      <div className="form-group">
        <label className="invisible font-pri fw-600" for="message">Message</label>
        <textarea className="col-pri form-control py-2" id="message" name="message" rows="2" placeholder="Message*" required></textarea>
      </div>
      <div className="pt-4">
      <ReCAPTCHA
      sitekey="6LeBksYpAAAAAEARKOI3Xjdb5_aJvbxU5ewKK0EM" 
      onChange={onChange}/>
      <button className='my-4 nav-link fw-500 btn-black border-0 rounded-3 border-0 text-white fs-ss-16 px-3 py-1' disabled={!verified}>
            Submit
        </button>
      </div>
    </form>
    </div>
                </div>
            </div>
        </div>
    </section>
    </>
    )
}

export default InquiryForm;