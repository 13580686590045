import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import Img1 from '../../assets/images/peg/Img1.webp';
import MiddleBannerImage from '../../assets/images/peg/MiddleBg.webp';
import { Link } from 'react-router-dom';
import FAQsSection from '../../components/FAQsSection';
import CommonBanner from '../../components/CommonBanner';
import BannerImg from '../../assets/images/peg/BannerImg.webp';
import { CircleFill } from 'react-bootstrap-icons';
import Bullets from '../../components/common/Bullets';
import { MetaTags } from 'react-meta-tags';

const BreathTests = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
      <MetaTags>
        <title> PEG Procedure: Empowering Your Nutritional Health </title> 
        <meta name="description" content="Ready to improve your nutrition? Choose PEG for expert care. Contact us today to schedule your consultation." />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="PEG Procedure: Empowering Your Nutritional Health" />
        <meta property="og:description" content="Ready to improve your nutrition? Choose PEG for expert care. Contact us today to schedule your consultation." />
        <meta property="og:url" content="https://northshoredigestivemedicinepc.com/" />
        <meta property="og:site_name" content="NSDM" />
        <meta property="og:image" content="https://northshoredigestivemedicinepc.com/og.webp" />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
        <meta property="og:image:secure_url" content="https://northshoredigestivemedicinepc.com/og.webp" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content="Ready to improve your nutrition? Choose PEG for expert care. Contact us today to schedule your consultation." />
        <meta name="twitter:title" content="PEG Procedure: Empowering Your Nutritional Health" />
        <meta name="twitter:image" content="https://northshoredigestivemedicinepc.com/og.webp"/>
      </MetaTags>
    <Header/>

    <CommonBanner
    Title="PEG"
    Image={BannerImg}
    />
    
    <section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary text-lg-center">
      <div className="row justify-content-center">
        <div className="col-11">
          <div className=''>
            <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            PEG (Percutaneous Endoscopic Gastrostomy)
            </h2>
            <h3 className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm pt-2">  PEG for Digestive Diseases
            </h3>
            <div className='pt-4'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            PEG, or Percutaneous Endoscopic Gastrostomy, is a medical procedure to insert a feeding tube directly into the stomach through the abdominal wall. This procedure is frequently performed on patients encountering difficulties in eating or swallowing due to various medical conditions, including cancer, neurological disorders, or congenital abnormalities.
            <div className='py-2'/> 
            PEG is a valuable intervention for patients requiring long-term nutritional support or those unable to consume food orally. It offers a reliable and efficient means of ensuring adequate nutrition and hydration, improving overall quality of life.
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>
    
    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-evenly align-items-center gy-md-4 ">
        <div className="col-11 col-lg-6">
          <div className=''>
          <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            PEG procedure: 
            </h2>
            <div className='pt-4'/>
            <div className='fs-ss-18 fw-500 lh-para'>
            <Bullets
            Title="Endoscopic Guidance:"
            Para="The procedure is conducted under endoscopic guidance, allowing the healthcare provider to visualize and access the stomach from within."
            />
            <Bullets
            Title="Abdominal Insertion:"
            Para="A small incision is made in the abdominal wall, through which a tube is inserted into the stomach."
            />
            <Bullets
            Title="Feeding Tube Placement:"
            Para="Once the tube is in place, it is secured to the abdominal wall to prevent displacement."

            />
            <Bullets
            Title="Feeding Support:"
            Para="The feeding tube provides a direct route for nutritional support, allowing essential nutrients to be delivered directly into the stomach."
            />                                                    
            </div>
          </div>
        </div>
        <div className="col-10 col-lg-5">
        <img className='w-100 px-lg-5' src={Img1} alt="" />        
        </div>
      </div>
    </section>

    <section className='pt-md-5 pt-3 mt-lg-5'/>

    <section id='website-responsive' className="container-fluid bg-digestive bg-digestive6 py-5 row justify-content-center gx-0">
    <section className="container-lg text-ss-secondary text-lg-center py-5">
      <div className="row justify-content-center">
        <div className="col-11">
          <div className=''>
            <h2 className='fs-ss-40 fw-600 lh-sm text-black'>
            Discover PEG for <br className='d-none d-md-block'/>Nourishment and Comfort
            </h2>
            <div className="pt-4">
            <div className='fs-ss-18 fw-500 lh-para'>
            If you'd like to learn more about procedures or to <br className='d-none d-md-block'/> find out if you are a fit candidate or not, please contact us!
            </div>
            <div className='pt-4 pt-lg-5'/>
          <Link to="/book-an-appointment" className='text-decoration-none py-md-2 py-1 fw-500 btn-transparent-dark text-ss-secondary rounded-5 fs-ss-16 px-3 border-dark border-1 border'>
            Book an Appointment
          </Link>          
          </div>
            </div>
          </div>
        </div>   
    </section>
    </section>

    <section className='pt-5 mt-lg-4'/>

    <section id='website-responsive' className="container-lg text-ss-secondary text-lg-center">
      <div className="row justify-content-center">
        <div className="col-11">
          <div className=''>
            <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            PEG: Safe and Secure Procedure  
            </h2>
            <div className='pt-4'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            From initial consultation to post-procedure follow-up, North Shore Digestive Medicine offers comprehensive support to ensure the best possible outcomes for our patients. We utilize advanced techniques and technologies to perform PEG procedures safely and effectively, helping individuals regain their ability to receive essential nutrition and hydration. 
            <div className='py-2'/>
            Schedule a consultation with North Shore Digestive Medicine today and discover how we can help you achieve optimal health and well-being.
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-4'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
    <div className='fs-ss-30 fw-600 fw-600 lh-sm text-center pb-4'>
      FAQS
      </div>
      <div className="row justify-content-center text-center">
        <div className="col-11 col-lg-11">
          <FAQsSection
          Title="Who needs a PEG procedure? "
          Para="PEG procedures are typically recommended for patients with conditions such as cancer, neurological disorders (e.g., stroke, Parkinson's disease), or congenital abnormalities that impair their ability to eat or swallow. These individuals may require long-term nutritional support via a feeding tube."
          />

          <FAQsSection
          Title="Is the PEG process uncomfortable? "
          Para="To ensure the comfort of the patient, the PEG treatment is carried out under sedation or anesthetic. After the procedure, patients may feel some mild discomfort or soreness at the insertion site, although this is usually well handled."
          />

          <FAQsSection
          Title="How much time does recovery from a PEG operation take? "
          Para="The recovery time varies from patient to patient but mainly depends upon the patient's pre-PEG medical status. "
          />

          <FAQsSection
          Title="What possible risks may a PEG installation pose? "
          Para="Even though PEG installation is thought to be safe, there are a few risks involved with the process, such as bleeding, infection, leakage surrounding the tube site, and anesthesia-related issues."
          />

        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>

    <Footer/>
    </>
  );
}

export default BreathTests;
