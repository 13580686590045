import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import Img1 from '../../assets/images/egd/Img1.webp';
import MiddleBannerImage from '../../assets/images/egd/MiddleBg.webp';
import { Link } from 'react-router-dom';
import FAQsSection from '../../components/FAQsSection';
import CommonBanner from '../../components/CommonBanner';
import BannerImg from '../../assets/images/egd/BannerImg.webp';
import { CircleFill } from 'react-bootstrap-icons';
import Bullets from '../../components/common/Bullets';
import { MetaTags } from 'react-meta-tags';

const BreathTests = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
      <MetaTags>
        <title> Upper Endoscopy (EGD) - Safe Solution of Digestive Diseases </title> 
        <meta name="description" content="Book your Upper Endoscopy today to diagnose and treat your digestive issues with precision and care from our experienced specialists" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Upper Endoscopy (EGD) - Safe Solution of Digestive Diseases" />
        <meta property="og:description" content="Book your Upper Endoscopy today to diagnose and treat your digestive issues with precision and care from our experienced specialists" />
        <meta property="og:url" content="https://northshoredigestivemedicinepc.com/" />
        <meta property="og:site_name" content="NSDM" />
        <meta property="og:image" content="https://northshoredigestivemedicinepc.com/og.webp" />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
        <meta property="og:image:secure_url" content="https://northshoredigestivemedicinepc.com/og.webp" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content="Book your Upper Endoscopy today to diagnose and treat your digestive issues with precision and care from our experienced specialists" />
        <meta name="twitter:title" content="Upper Endoscopy (EGD) - Safe Solution of Digestive Diseases" />
        <meta name="twitter:image" content="https://northshoredigestivemedicinepc.com/og.webp"/>
      </MetaTags>
    <Header/>

    <CommonBanner
    Title="Upper Endoscopy"
    Image={BannerImg}
    />
    
    <section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary text-lg-center">
      <div className="row justify-content-center">
        <div className="col-11">
          <div className=''>
            <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            Upper Endoscopy (EGD)
            </h2>
            <h3 className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm pt-2"> Upper Endoscopy (EGD) for Digestive Diseases </h3>
            <div className='pt-4'/>
            <div className='fs-ss-16 fw-400 lh-para'>
              Esophagogastroduodenoscopy (EGD), sometimes called upper endoscopy, is used to evaluate the upper gastrointestinal tract, which comprises the esophagus, stomach, and approximal portion of the small intestine. An endoscope, a thin, flexible tube with a camera at the tip, is introduced via the mouth and directed down the throat during an endoscopy to view the inside of the digestive track.
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>
    
    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-evenly align-items-center gy-md-4 ">
        <div className="col-11 col-lg-6">
          <div className=''>
          <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            Who Needs an EGD? 
            </h2>
            <div className='pt-4'/>
            EGD is recommended for individuals who may have symptoms or conditions affecting the upper digestive tract, such as: 
            <div className='fs-ss-18 fw-500 lh-para pt-3'>
            <Bullets
            Title="Persistent Heartburn or Acid Reflux"
            />
            <Bullets
            Title="Difficulty Swallowing (Dysphagia)"
            />
            <Bullets
            Title="Unexplained Abdominal Pain"
            />
            <Bullets
            Title="Gastrointestinal Bleeding"
            />
            <Bullets
            Title="Screening for Cancer or Precancerous Conditions"
            />                                                      
            </div>
            <div className='pt-2'/>
            Overall, EGD is a safe and effective means of diagnosing and treating various upper digestive tract conditions. If you are experiencing symptoms suggestive of gastrointestinal issues or have risk factors for upper digestive tract diseases, consult with your healthcare provider to determine if an EGD is appropriate for you.
          </div>
        </div>
        <div className="col-11 col-lg-5">
        <img className='w-100 px-lg-5' src={Img1} alt="" />        
        </div>
      </div>
    </section>

    <section className='pt-md-5 pt-3 mt-lg-5'/>

    <section id='website-responsive' className="container-fluid bg-digestive bg-digestive2 py-5 row justify-content-center gx-0">
    <section className="container-lg text-ss-secondary text-lg-center py-5">
      <div className="row justify-content-center">
        <div className="col-11">
          <div className=''>
            <h2 className='fs-ss-40 fw-600 lh-sm text-black'>
            Discover the Power of Upper Endoscopy
            </h2>
            <div className="pt-4 pt-lg-5">
            <div className='fs-ss-18 fw-500 lh-para'>
            If you'd like to learn more about procedures or to <br className='d-none d-md-block'/> find out if you are a fit candidate or not, please contact us!
            </div>
            <div className='pt-4 pt-lg-5'/>
          <Link to="/book-an-appointment" className='text-decoration-none py-md-2 py-1 fw-500 btn-transparent-dark text-ss-secondary rounded-5 fs-ss-16 px-3 border-dark border-1 border'>
            Book an Appointment
          </Link>          
          </div>
            </div>
          </div>
        </div>   
    </section>
    </section>

    <section className='pt-5 mt-lg-4'/>

    <section id='website-responsive' className="container-lg text-ss-secondary text-lg-center">
      <div className="row justify-content-center">
        <div className="col-11">
          <div className=''>
            <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            How North Shore Helps?
            </h2>
            <div className='pt-4'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            At North Shore, we understand the importance of early detection and effective management of gastrointestinal conditions. Our team of skilled gastroenterologists specializes in performing EGD procedures with precision and care.
            <div className='py-2'/>
            From initial consultation to post-procedure follow-up, North Shore Digestive Medicine offers comprehensive support to our patients. If you are experiencing symptoms suggestive of gastrointestinal issues or have been advised to undergo an EGD, trust our professional team to provide expert care. Schedule a consultation with us today and take proactive steps towards better digestive health. Your health and satisfaction are our top priorities.
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-4'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
    <div className='fs-ss-30 fw-600 fw-600 lh-sm text-center pb-4'>
      FAQS
      </div>
      <div className="row justify-content-center text-center">
        <div className="col-11 col-lg-11">
          <FAQsSection
          Title="What is an EGD? "
          Para="EGD, or Upper Endoscopy, is a procedure used to examine the upper gastrointestinal tract, including the esophagus, stomach, and duodenum."
          />

          <FAQsSection
          Title="Is EGD painful? "
          Para="EGD is typically performed under sedation or anesthesia to ensure patient comfort."
          />

          <FAQsSection
          Title="How long does an EGD procedure take? "
          Para="The duration of an EGD procedure varies depending on the findings and any necessary interventions. On average, the diagnostic EGD takes less than 10 minutes."
          />

          <FAQsSection
          Title="What can EGD diagnose? "
          Para="EGD can diagnose various upper digestive tract conditions, including ulcers, inflammation, strictures, tumors, and abnormal growths. It can also detect the presence of gastrointestinal bleeding and provide tissue samples for biopsy."
          />

          <FAQsSection
          Title="Is EGD safe? "
          Para="EGD is considered a safe procedure when performed by experienced healthcare professionals. However, like any medical procedure, there are risks, including perforation, bleeding, and adverse reactions to sedation. Your professional healthcare provider will discuss these risks with you before the procedure."
          />
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>

    <Footer/>
    </>
  );
}

export default BreathTests;
