import React,{useEffect} from 'react'
import {Link} from 'react-router-dom'
import Header from '../components/layout/Header'
import Footer from '../components/layout/Footer'

const Error = (props) => {
    useEffect(() => {
        window.document.title = "Page Not Found | North Shore Digestive Medicine, PC"
    }, [])
    return (
        <>
        <Header/>
            <section id="website-responsive" className='py-5'>
                <section className='py-5 my-md-5'>
                    <div className='container-fluid text-center'>
                        <div className=''>
                            <div className="text-ss-primary fs-ss-68 fw-500">
                                404 Error
                            </div>
                        <h4 className='fw-700 fs-ss-30 mb-4'>This page doesn't seem to exist.</h4>
                        <div className='fs-ss-22 fw-500'>It looks like the link pointing here was faulty. Maybe try searching?</div>
                        <div className="pt-4 pt-md-5">
                            <Link to="/" className='text-decoration-none py-md-2 py-1 fw-500 btn-transparent-dark text-ss-secondary rounded-5 fs-ss-16 px-3 border-dark border-1 border'>
                                Back to Home
                            </Link>          
                        </div>
                        </div>
                    </div>
                </section>
            </section>
        <Footer/>
        </>
    )
}

export default Error;
