import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BannerImg from '../assets/images/Digestive.webp';
import Img1 from '../assets/images/weight-loss/Img1.webp';
import Img3 from '../assets/images/esg-landing-page/Img3.webp';
import MiddleBannerImage from '../assets/images/weight-loss/MiddleBg.webp';
import MiddleBanner2 from '../components/common/MiddleBanner2';
import CommonBanner from '../components/CommonBanner';
import ServIcon4 from '../assets/images/home/4.webp';
import ServIcon5 from '../assets/images/home/5.webp';
import ServIcon6 from '../assets/images/home/6.webp';
import ServIcon7 from '../assets/images/home/7.webp';
import ServIcon8 from '../assets/images/home/8.webp';
import ServIcon9 from '../assets/images/home/9.webp';
import ServIcon10 from '../assets/images/home/10.webp';
import FAQsSection from '../components/FAQsSection';
import SingleService2 from '../components/common/SingleService2';
import ServIcon1 from '../assets/images/weight-loss/2.webp';
import ServIcon2 from '../assets/images/weight-loss/1.webp';
import ServIcon3 from '../assets/images/weight-loss/3.webp';
import Video1 from '../assets/images/home/Video1.mp4';
import Video2 from '../assets/images/home/Video2.mp4';
import Video3 from '../assets/images/home/Video3.mp4';
import SingleService from '../components/common/SingleService';
import { Link } from 'react-router-dom';
import { TelephoneFill } from 'react-bootstrap-icons';
import Img2 from '../assets/images/esg-landing-page/Img2.webp';
import Bullets from '../components/common/Bullets';
import { MetaTags } from 'react-meta-tags';


const Digestive = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
      <MetaTags>
        <title> Digestive Procedures - The Modern Solution for Weight Loss </title> 
        <meta name="description" content="Discover innovative digestive procedures for effective and safe weight loss solutions. Get specialized care from our experienced MD." />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Digestive Procedures - The Modern Solution for Weight Loss" />
        <meta property="og:description" content="Discover innovative digestive procedures for effective and safe weight loss solutions. Get specialized care from our experienced MD." />
        <meta property="og:url" content="https://northshoredigestivemedicinepc.com/" />
        <meta property="og:site_name" content="NSDM" />
        <meta property="og:image" content="https://northshoredigestivemedicinepc.com/og.webp" />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
        <meta property="og:image:secure_url" content="https://northshoredigestivemedicinepc.com/og.webp" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content="Discover innovative digestive procedures for effective and safe weight loss solutions. Get specialized care from our experienced MD." />
        <meta name="twitter:title" content="Digestive Procedures - The Modern Solution for Weight Loss" />
        <meta name="twitter:image" content="https://northshoredigestivemedicinepc.com/og.webp"/>
      </MetaTags>
    <Header/>

    <CommonBanner
    Title="Digestive Procedures"
    Image={BannerImg}
    />

<section className='pt-5 mt-lg-5'/>

<section id='website-responsive' className="container-lg text-ss-secondary text-lg-center">
      <div className="row justify-content-center">
        <div className="col-11">
          <div className=''>
            <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            Enhance Your Digestive Well-Being
            </h2>
            <h3 className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm pt-2"> Discover Innovative Solutions for Digestive Health
            </h3>
            <div className='pt-3 pt-md-4'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            North Shore Digestive Medicine is dedicated to providing exceptional care and innovative solutions for digestive health. Moreover, we provide non-surgical weight loss procedures and nutritional counseling in Long Island. Our comprehensive suite of digestive procedures encompasses a range of diagnostic and therapeutic interventions to meet each patient's unique needs.  
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-center text-center pt-4 pt-md-5 gy-4">
        <SingleService2
        Image={ServIcon4}
        Title="Colonoscopy"
        Link_To="/colonoscopy"
        Space2="px-3 px-md-0"
        />

        <SingleService2
        Image={ServIcon5}
        Title={<> Upper Endoscopy <br /> (EGD) </>}
        Link_To="/upper-endoscopy"
        Space=""
        Space2="px-3 px-md-0"
        />
        
        <SingleService2
        Image={ServIcon7}
        Title="ERCP"
        Link_To="/ercp"
        Space2="px-3 px-md-0"
        />     
        
        <SingleService2
        Image={ServIcon10}
        Title={<> Radio Frequency <br /> Ablation Therapy </>}
        Link_To="/radio-frequency-ablation-therapy"
        Space2="px-3 px-md-0"
        Space="d-block d-md-none"
        />              
      </div>
      
      <div className="row justify-content-center text-center pt-4 pt-lg-5 gy-4">
        <SingleService2
        Image={ServIcon8}
        Title={<> CapsoVision <br /> Endoscopy </>}
        Link_To="/capsovision-endoscopy"
        Space2="px-3 px-md-0"
        />

        <SingleService2
        Image={ServIcon9}
        Title="PEG"
        Link_To="/peg"
        Space=""
        Space2="px-3 px-md-0"
        />

        <SingleService2
        Image={ServIcon10}
        Title={<> Radio Frequency <br /> Ablation Therapy </>}
        Link_To="/radio-frequency-ablation-therapy"
        Space2="px-3 px-md-0"
        Space="d-none d-md-block"
        />   
      </div>
    </section>
    
    <section className='pt-5 mt-lg-5'/>
    
    <section id='website-responsive' className={`container-fluid bg-middle-banner2 py-5`} style={{backgroundImage:`url(${MiddleBannerImage})`}}>
      <div className="row justify-content-center text-center text-md-start">
        <div className="col-11 col-lg-6 py-md-5">
          <h2 className='fw-700 text-white fs-ss-middle-banner2 text-uppercase lh-sm'>
            Empowering You to Reach Your Health Goals, Together
          </h2>
          <div id='btn' className="pt-4 pt-lg-5">
          <a href="tel:+16319978135" className='text-decoration-none py-2 fw-500 btn-middle-banner2 rounded-5 fs-ss-18 px-4 border-white border-1 border'>
         <TelephoneFill className='me-2'/> 631-997-8135
          </a>          
          </div>
        </div>
        <div className="col-4 col-lg-4"/>        
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-evenly align-items-center gy-4">
      <div className="col-11 col-lg-4 d-none d-lg-block ">
        <img className='w-100' src={Img2} alt="" />        
        </div>
        <div className="col-11 col-lg-6">
          <div className=''>
            <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            Your Digestive Health, Our Priority
            </h2>
            <div className='pt-4'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            At North Shore Digestive Medicine, we understand the importance of digestive health in overall well-being. Our expert team is here to guide you through various advanced procedures for your specific needs. Whether you require diagnostic testing or therapeutic interventions, trust North Shore Digestive Medicine to provide compassionate care and personalized treatment plans. Contact us to schedule your consultation for digestive health.   
            </div>
          </div>
        </div>
        <div className="col-10 col-md-7 col-lg-4 d-block d-lg-none">
        <img className='w-100' src={Img2} alt="" />        
        </div>
      </div>
    </section>
    
    <section className='pt-5 mt-lg-5'/>

    <Footer/>
    </>
  );
}

export default Digestive;
