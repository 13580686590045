import React from 'react';
import {Link} from 'react-router-dom';

const SingleService2 = (props) => {
  return (
    <>
    <div className={`col-serv2 text-center ${props.Space}`}>
      <Link className='text-decoration-none text-ss-secondary' to={props.Link_To}>
      <img className={`w-services2 ${props.Space2} `} src={props.Image} alt={props.Alt} />
      <h4 className='fw-600 pt-2 pt-lg-4 fs-ss-18 lh-sm'>
        {props.Title}
      </h4>
      </Link>
    </div>
    </>
  )
}

export default SingleService2