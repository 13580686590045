import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BannerImg from '../assets/images/our-team/Banner.webp';
import Img1 from '../assets/images/esg-landing-page/Img4.webp';
import CommonBanner from '../components/CommonBanner';
import Bullets from '../components/common/Bullets';
import {Instagram, Facebook, TelephoneFill,EnvelopeFill,GeoAltFill} from 'react-bootstrap-icons';

const OurTeam = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
      <title>
      Our Team - North Shore Digestive Medicine
      </title>
    </Helmet>
    <Header/>

    <CommonBanner
    Title="Our Team"
    Image={BannerImg}
    />
    
    <section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-evenly align-items-center gy-4">
        <div className="col-11 col-lg-6">
          <div className=''>
          <div className='fs-ss-40 fw-600 lh-sm text-uppercase'>
          DR. Farzad Forohar, M.D <br className='' /> 
            <div className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm pt-2"> Professionalism. Excellence. Experience. </div>
            </div>
            <div className='pt-4'/>
            <div className='fs-ss-18 fw-400 lh-para'>
            After graduating from Vailsburg high school in New Jersey, Dr. Forohar attended University of Arkansas where he obtained a degree in chemical engineering and was selected to Omega Chi Epsilon , chemical engineering, honor society. 
            <div className="py-2" />
            He obtained his medical degree from Pecs medical University, one of the oldest medical Schools in Europe , and post medical education at Montefiore Medical Center in New York. This training consisted of three years of internal medicine and three years of gastroenterology. He then moved to Long Island, New York and has been practicing gastroenterology since 1999. 
            </div>
            {/* Dr. Forohar’s past responsibilities at Saint Catherine’s of Siena Medical Center include: */}
            {/* <div className="pt-3">
            <Bullets
            Title="Chairman of the Performance Improvement Counsel (2011)"
            />
            <Bullets
            Title="Medical Staff President (2012)"
            />
            <Bullets
            Title="Chairman of the Credentials Committee (2013)"
            />
            </div> */}
          </div>
        </div>
        <div className="col-10 col-lg-5">
        <img className='w-100 px-lg-5' src={Img1} alt="" />        
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>

        <section className='container-fluid pe-md-5'>
                <div className=''>
                <div className="">
                    <div className='row justify-content-center text-center'>
                        <div className='col-11 col-md-8 col-lg-4 mb-3 border-end border-2'>
                            <div className='my-2'>
                                <TelephoneFill className='text-ss-primary' size={50} />
                                <div className='fs-ss-26 mt-3 mb-md-3'>
                                    <div className='fw-600' >Phone</div>
                                </div>
                            </div>
                            <div className='mb-2'>
                            <a href="tel:+16319978135" target='_blank' className='text-decoration-none text-ss-secondary fw-500 fs-ss-18'>
                              631-997-8135
                            </a>
                            </div>
                            <div className=''>
                            <a href="tel:+16317245300" target='_blank' className='text-decoration-none text-ss-secondary fw-500 fs-ss-18'>
                              631-724-5300
                            </a>
                            </div>
                            
                        </div>
                        <div className='col-11 col-md-8 col-lg-4 mb-3 border-end border-2'>
                            <div className='my-2'>
                                <EnvelopeFill className='text-ss-primary' size={50} />
                                <div className='fs-ss-26 mt-3 mb-md-3'>
                                    <div className='fw-600' >Email</div>
                                </div>
                            </div>
                            <div className='my-2'>
                            <a href="mailto:weightmanagement@drforohar.com" target='_blank' className='text-decoration-none text-ss-secondary fw-500 fs-ss-18'>
            <span className=""> weightmanagement@drforohar.com </span>
            </a>
                            </div>
                            
                        </div>

                        <div className='col-11 col-md-8 col-lg-4 mb-3'>
                            <div className='my-2'>
                                <GeoAltFill className='text-ss-primary' size={50} />
                                <div className='fs-ss-26 mt-3 mb-md-3'>
                                    <div className='fw-600' >Address</div>
                                </div>
                            </div>
                            <div className='my-2 px-md-4'>
                            <a href="https://maps.app.goo.gl/FEjmEcx22Gw3xsvF6" target='_blank' className='text-decoration-none text-ss-secondary fw-500 fs-ss-18'>
            50 Route 111 Suite 302 Smithtown, <br className='d-none d-lg-block'/> 
            <span className='ms-lg-4'> New York 11787 </span> 
            </a>
                            </div>
                            
                        </div>
                    </div>
                </div>
                </div>
            </section>

            <section className='pt-5 mt-lg-5'/>

    <Footer/>
    </>
  );
}

export default OurTeam;
