import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BannerImg from '../assets/images/esg/BannerImg.webp';
import Img1 from '../assets/images/esg/Img1.webp';
import Img2 from '../assets/images/esg/Img2.webp';
import MiddleBannerImage from '../assets/images/esg/MiddleBg.webp';
import Bullets from '../components/common/Bullets';
import Video1 from '../assets/images/home/Video2.mp4';
import CommonBanner from '../components/CommonBanner';
import FAQsSection from '../components/FAQsSection';
import { TelephoneFill } from 'react-bootstrap-icons';
import { MetaTags } from 'react-meta-tags';

const ESG = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
      <MetaTags>
        <title> Endoscopic Sleeve Gastroplasty (ESG): Long Island, NY </title> 
        <meta name="description" content="Endoscopic Sleeve Gastroplasty (ESG) in Long Island, NY, a minimally invasive weight loss procedure for lasting results. Book Appointment Today !" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Endoscopic Sleeve Gastroplasty (ESG): Long Island, NY" />
        <meta property="og:description" content="Endoscopic Sleeve Gastroplasty (ESG) in Long Island, NY, a minimally invasive weight loss procedure for lasting results. Book Appointment Today !" />
        <meta property="og:url" content="https://northshoredigestivemedicinepc.com/" />
        <meta property="og:site_name" content="NSDM" />
        <meta property="og:image" content="https://northshoredigestivemedicinepc.com/og.webp" />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
        <meta property="og:image:secure_url" content="https://northshoredigestivemedicinepc.com/og.webp" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content="Endoscopic Sleeve Gastroplasty (ESG) in Long Island, NY, a minimally invasive weight loss procedure for lasting results. Book Appointment Today !" />
        <meta name="twitter:title" content="Endoscopic Sleeve Gastroplasty (ESG): Long Island, NY" />
        <meta name="twitter:image" content="https://northshoredigestivemedicinepc.com/og.webp"/>
      </MetaTags>
    <Header/>

    <CommonBanner
    Title={<>Endoscopic <br className='d-none d-lg-block' /> Sleeve Gastroplasty </>}
    Image={BannerImg}
    />

<section className='pt-5 mt-lg-5'/>

<section id='website-responsive' className="container-lg text-ss-secondary text-lg-center bg-blue-pattern2">
<div className="row justify-content-center">
    <div className="col-11">
      <div className=''>
      <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
        North Shore Digestive Medicine ESG 
        </h2>
        <div className='pt-3 pt-md-4'/>
        <div className='fs-ss-16 fw-400 lh-para text-start'>
        At North Shore Digestive Medicine, we offer Endoscopic Sleeve Gastroplasty (ESG), a procedure with a practical approach to weight loss without surgery. We focus on providing personalized, competent and compassionate care to each patient on their weight loss journey. 
        <div className='py-2'/> 
        From the moment you start your weight loss journey with us, you’ll receive personalized attention and support every step of the way.  Our commitment is to redefine the standard for endoscopic non-surgical weight loss procedures, ensuring that every encounter and experience within our care reflects our vision of excellence.   
        </div>
        </div>
      </div>
      </div>

      <div className="row justify-content-center text-center pt-5 mt-lg-4">
        <div className="col-11 col-lg-7 bg-grey-2 px-2 pt-2 rounded-3 shadow-ss-1 btn-black">
          <video className='w-100 rounded-3' src={Video1} autoPlay={true} loop="true" muted controls />
{/* 
          <iframe width="100%" height="500" autoPlay={true} src="https://www.youtube.com/embed/OtqxDT0IlHI?si=R5HPrWKHt4qiT7ft" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
        </div>
</div>
</section>

<section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-evenly align-items-center gy-4">
        <div className="col-11 col-lg-4 d-none d-lg-block ">
        <img className='w-100' src={Img1} alt="" />        
        </div>
        <div className="col-11 col-lg-6">
          <div className=''>
            <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            Why Choose Us?
            </h2>
            <div className='fs-ss-16 fw-400 lh-para pt-2'>
            What makes us unique is the atmosphere we cultivate throughout your journey for endoscopic weight loss.   
            </div>
            <div className='pt-3 pt-md-4'/>
            <div className='fs-ss-16 fw-600 lh-para'>
              <Bullets
              Title={<> Effective Procedure with Minimal Side Effects </>}
              />
              <Bullets
              Title={<> An Average Total Body Weight Loss of 18-20% </>}
              />
              <Bullets
              Title={<> No Scars, No Cuts </>}
              />
              <Bullets
              Title={<> Nonsurgical Outpatient Procedure  </>}
              />
              <Bullets
              Title={<> Utilizing Non-Diet Strategy for Breaking the Eating “Habit” </>}
              />
            </div>
          </div>
        </div>
        <div className="col-10 col-md-7 col-lg-4 d-block d-lg-none">
        <img className='w-100' src={Img1} alt="" />        
        </div>
      </div>
    </section>

    <section className='pt-md-5 pt-3 mt-lg-3'/>
    

    <section id='website-responsive' className={`container-fluid bg-middle-banner2 py-5`} style={{backgroundImage:`url(${MiddleBannerImage})`}}>
      <div className="row justify-content-center pt-md-5">
        <div className="col-11 col-lg-6 py-md-5 text-center text-lg-start">
          <h2 className='fw-700 text-white fs-ss-middle-banner2 text-uppercase lh-sm'>
            Transform Your <br /> Body, the Non<br/> Surgical Way!
          </h2>
          <div id='btn' className="pt-4 pt-lg-5">
          <a href="tel:+16319978135" className='text-decoration-none py-2 fw-500 btn-middle-banner2 rounded-5 fs-ss-18 px-4 border-white border-1 border'>
         <TelephoneFill className='me-2'/> 631-997-8135
          </a>          
          </div>
        </div>
        <div className="col-4 col-lg-4"/>        
      </div>
    </section>

<section className='pt-5 mt-lg-5'/>

<section id='website-responsive' className="container-lg text-ss-secondary">
    <div className='fs-ss-30 fw-600 fw-600 lh-sm text-center pb-4'>
      FAQS
      </div>
      <div className="row justify-content-center text-center">
        <div className="col-11 col-lg-11">
          <FAQsSection
          Title="What is Endoscopic Sleeve Gastroplasty (ESG)?"
          Para={<>
          North Shore Digestive Medicine offers Endoscopic Sleeve Gastroplasty (ESG), an advanced, minimally invasive technique for weight loss.  Without requiring any surgical incisions, the stomach is reduced in size by 60-75% using an endoscopic suturing device.   
          <div className='py-2'/> 
          The stomach is sutured during the procedure to form a smaller, tube-shaped pouch.  This lowers the stomach’s capacity to store food, which results in earlier sensations of fullness and less food consumption.   
          </>}
          />

          <FAQsSection
          Title="What’s the name of the device inserted through the mouth to stitch and shrink the stomach for weight loss? "
          Para={<>
          Overstitch<span className='fs-ss-22'>®</span> is a small suturing device attached to the end of an endoscope and then passes to the stomach under general anesthesia. Our specifically trained physician then sews your stomach into a small shape.   
          
          <div className='fw-600 py-2'> Your Possibility of Losing Weight with ESG </div>
          
          Unlike conventional bariatric procedures, the digestive tract is not rerouted or any portion of the stomach removed during an ESG.  To ensure that patients are completely informed before making decisions, our knowledgeable North Shore Digestive Medicine staff offers thorough information on the ESG procedure, including its advantages, risks, and predicted outcome of an average total body weight loss of 18-20%.     
          </>}
          />

          <FAQsSection
          Title="How does the smaller stomach size in the ESG procedure contribute to portion control during meals? "
          Para="The ESG procedure reduces the stomach size and length.  This smaller stomach acts as portion control, limiting food intake during meals.  Additionally, the new shape of the stomach slows digestion, prolonging the feeling of fullness and promoting weight loss. The reduced stomach size enhances satiety and increases satisfaction."
          />

          <FAQsSection
          Title="How long does the ESG procedure take?"
          Para="The Endoscopic Sleeve Gastroplasty (ESG) procedure typically takes 60-90 minutes.  It is performed under general anesthesia; patients can usually return home the same day.  At North Shore Digestive Medicine, our team guides patients through every process step, from pre-procedure preparation to post-procedure care."
          />

          <FAQsSection
          Title="What are the potential risks or side effects of ESG? "
          Para="Like any medical procedure, Endoscopic Sleeve Gastroplasty (ESG) carries risks and potential side effects, even though it is generally considered safe.  These could include bleeding, nausea, vomiting, or momentary discomfort.  Serious problems are uncommon, however."
          />                    
        </div>
      </div>
    </section>

<section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-evenly align-items-center gy-4 pb-md-0 pb-3">
      <div className="col-11 col-lg-4 d-none d-lg-block ">
        <img className='w-100' src={Img2} alt="" />        
        </div>
        <div className="col-11 col-lg-6">
          <div className=''>
            <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            Small Changes, Big Results
            </h2>
            <h3 className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm pt-2"> Discover the Power of ESG at North Shore Digestive Medicine </h3>
            <div className='pt-3 pt-md-4'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            Ready to take the next step towards achieving your weight loss goals? Experience the transformative effects of the ESG procedure today.  With a smaller stomach size acting as natural portion control and the new shape promoting prolonged feelings of fullness, our endoscopic sleeve offers a path to enhanced satiety and satisfaction.  Contact us to schedule your consultation.   
            </div>
          </div>
        </div>
        <div className="col-10 col-md-7 col-lg-4 d-block d-lg-none">
        <img className='w-100' src={Img2} alt="" />        
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>

    <Footer/>
    </>
  );
}

export default ESG;
