import React from 'react';
import { Link } from "react-router-dom"
import Navbar from 'react-bootstrap/Navbar';
import HeaderLogo from '../../assets/images/home/HeaderLogo.webp';
import PatientForm from '../../assets/images/PIF.pdf';
import Offcanvas from 'react-bootstrap/Offcanvas';
import NavBarLink from './NavBarLink';
import NavBarLink2 from './NavBarLink2';
import { useLocation } from "react-router-dom";
import TopHeader from './TopHeader';
import { NavbarBrand } from 'react-bootstrap';
import { TelephoneFill } from 'react-bootstrap-icons';

const Header = () => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  return (
    <>
    <TopHeader/>
    <section className="container-fluid border-top-grey px-lg-5 bg-light">
      <Navbar expand="lg" className="">
        <div className='container-fluid gx-0'>
        <Navbar.Brand className='navbar-brand py-2 d-block d-lg-none'>
          <Link className="" to="/">
            <img
            src={HeaderLogo}
            alt="Logo"
            className="header-logo"
            />
          </Link>
          </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-xl`} className='border-0 rounded-0 toggle-icon'/>

            <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-xl`}
            aria-labelledby={`offcanvasNavbarLabel-expand-xl`}
            placement="start"
            className="navbar-offcanvas"
            >
            <Offcanvas.Header closeButton className=''>

                <Offcanvas.Title className='' id={`offcanvasNavbarLabel-expand-xl`}>
                    <Link
                    className=""
                    to="/"
                    >
                    <img
                        src={HeaderLogo}
                        alt="Logo"
                        className="w-75"
                    />
                    </Link>
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
            <ul className="navbar-nav">

              
          <NavBarLink
          Name="Home"
          Link_To=""
          />
          
          <NavBarLink2
              Name="About Us"
              Link_To="about-us"
              DropdownMenu={
                <>
              <NavBarLink
              Name="Our Team"
              Link_To="our-team"
              Border="border-grey border-bottom border-1 "          
              />
              </>}
              />

              <NavBarLink2
              Name="Weight Loss Procedures"
              Link_To="non-surgical-weight-loss-long-island-ny"
              DropdownMenu={
                <>
              <NavBarLink
              Name={<>Endoscopic Sleeve <br className='d-none d-lg-block' /> Gastroplasty (ESG)</>}
              Link_To="esg-long-island-ny"
              Border="border-grey border-bottom border-1 "          
              />
              <NavBarLink
              Name="Orbera Balloon"
              Link_To="orbera-balloon-long-island-ny"
              Border="border-grey border-bottom border-1 "          
              />              
              <NavBarLink
              Name="Gastric Revisions"
              Link_To="gastric-revisions-long-island-ny"
              />
              </>}              
              />
              <NavBarLink2
              Name="Digestive Procedures"
              Link_To="digestive-procedures"
              DropdownMenu={
                <>
              <NavBarLink
              Name="Colonoscopy"
              Link_To="colonoscopy"
              Border="border-grey border-bottom border-1 "          
              />
              <NavBarLink
              Name=" Upper Endoscopy (EGD) "
              Link_To="upper-endoscopy"
              Border="border-grey border-bottom border-1 "          
              />              
              {/* <NavBarLink
              Name="Breath Tests"
              Link_To="breath-tests"
              Border="border-grey border-bottom border-1 "          
              /> */}
              <NavBarLink
              Name={<>Endoscopic Retrograde <br className='d-none d-lg-block' /> Cholangiopancreatography (ERCP)</>}
              Link_To="ercp"
              Border="border-grey border-bottom border-1 "          
              />
              <NavBarLink
              Name="CapsoVision Endoscopy"
              Link_To="capsovision-endoscopy"
              Border="border-grey border-bottom border-1 "          
              />
              <NavBarLink
              Name={<>Percutaneous Endoscopic <br className='d-none d-lg-block' /> Gastrostomy (PEG)</>}
              Link_To="peg"
              Border="border-grey border-bottom border-1 "          
              />
              <NavBarLink
              Name={<>Radio Frequency Ablation <br className='d-none d-lg-block' /> Therapy</>}
              Link_To="radio-frequency-ablation-therapy"
              />
                </>
              }
              />  
              <NavBarLink
              Name="Education"
              Link_To="education"
              />              
              <NavBarLink
              Name="Success Stories"
              Link_To="success-stories"
              />
              
              <li className="nav-item fs-ss-links right-menu d-block d-lg-none">
                <div class="btn-group dropdown">
                  <span className={splitLocation[1] === "patient-resources" ? "nav-link hover-ss-primary text-ss-primary fw-500" : "nav-link hover-ss-primary text-ss-secondary fw-500"}> Patient Resources </span>
                  <Link class="btn px-4 dropdown-toggle dropdown-toggle-split border-0 text-black" data-bs-toggle="dropdown-menu" aria-expanded="false">
                    <span class="visually-hidden">Toggle Dropdown</span>
                  </Link>
                  </div>
                  <ul className="dropdown-menu hover1 fs-ss-links w-content">
                  <NavBarLink
              Name="BMI Calculator"
              Link_To="bmi-calculator"
              Border="border-grey border-bottom border-1 "          
              />
              <NavBarLink
              Name="FAQs"
              Link_To="faqs"
              Border="border-grey border-bottom border-1 "          
              />     

              <li className="nav-item fs-ss-links text-ss-secondary me-lg-3">
                <a className={splitLocation[1] === "patient-login" ? "nav-link hover-ss-primary text-ss-primary fw-500 border-grey border-bottom border-1" : "nav-link hover-ss-primary text-ss-secondary fw-500 border-grey border-bottom border-1"} href=""> Patient Login </a>
              </li>
              
              <li className="nav-item fs-ss-links text-ss-secondary me-lg-3">
                <a target='_blank' className={splitLocation[1] === "patient-interview-form" ? "nav-link hover-ss-primary text-ss-primary fw-500 border-grey border-bottom border-1" : "nav-link hover-ss-primary text-ss-secondary fw-500 border-grey border-bottom border-1"} href={PatientForm}> Patient Interview Form </a>
              </li>                                                                               
              <NavBarLink
              Name="Financing and Insurance"
              Link_To="financing-and-insurance"
              />
                  </ul>
              </li>
              
              <li className="nav-item fs-ss-links right-menu d-none d-lg-block me-lg-3">
                <span className={splitLocation[1] === "patient-resources" ? "nav-link dropdown-toggle nav-link hover-ss-primary text-ss-primary fw-500" : "nav-link dropdown-toggle hover-ss-primary text-ss-secondary fw-500"} >Patient Resources </span>
                <ul className="dropdown-menu hover1 fs-ss-links w-content bg-light2 ">
                <NavBarLink
              Name="BMI Calculator"
              Link_To="bmi-calculator"
              Border="border-grey border-bottom border-1 "          
              />
              <NavBarLink
              Name="FAQs"
              Link_To="faqs"
              Border="border-grey border-bottom border-1 "          
              />     

              <li className="nav-item fs-ss-links text-ss-secondary me-lg-3">
                <a target='_blank' className={splitLocation[1] === "patient-login" ? "nav-link hover-ss-primary text-ss-primary fw-500 border-grey border-bottom border-1" : "nav-link hover-ss-primary text-ss-secondary fw-500 border-grey border-bottom border-1"} href="https://northshoredigestivemedicinepc.mygportal.com/PP6-0-0/Account/LogOn"> Patient Login </a>
              </li>
              
              <li className="nav-item fs-ss-links text-ss-secondary me-lg-3">
                <a target='_blank' className={splitLocation[1] === "patient-interview-form" ? "nav-link hover-ss-primary text-ss-primary fw-500 border-grey border-bottom border-1" : "nav-link hover-ss-primary text-ss-secondary fw-500 border-grey border-bottom border-1"} href={PatientForm}> Patient Interview Form </a>
              </li>                                                                               
              <NavBarLink
              Name="Financing and Insurance"
              Link_To="financing-and-insurance"
              />
                </ul>
              </li>
              <NavBarLink
              Name="Contact Us"
              Link_To="contact-us"
              /> 
              
              
              <div className="pt-4 d-block d-md-none">
                <a href="tel:+16317245300" target="_blank" className='text-center text-decoration-none shadow-ss-1 btn-ss-primary border-0 rounded-3 border-0 text-white fs-ss-links px-4 py-2'>
                <TelephoneFill className='me-2'/> 631-724-5300
                </a>
                <div className="pt-4 mt-2" />
                <a href="tel:+16319978135" target="_blank" className='text-center text-decoration-none shadow-ss-1 btn-ss-primary border-0 rounded-3 border-0 text-white fs-ss-links px-4 py-2'>
                  <TelephoneFill className='me-2'/> 631-997-8135 &nbsp;
                  </a>
              </div>

              </ul>
              </Offcanvas.Body>
              </Navbar.Offcanvas>
              </div>        
              </Navbar>
              </section>
    </>
  )
}

export default Header