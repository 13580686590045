import React from 'react';
import {Link} from 'react-router-dom';

const CommonBanner = (props) => {
  return (
    <>
    <section id='website-responsive' className="container-fluid common-banner py-5 shadow-ss-1 text-ss-secondary" style={{backgroundImage:`url(${props.Image})`}}>
      <div className="row justify-content-center align-items-center py-md-5">
        <div className="col-11 col-lg-10 py-md-5 my-lg-5 py-4">
          <h1 className="lh-sm fw-600 banner-title text-uppercase">
            {props.Title}
          </h1>
          <div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default CommonBanner