import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import Img1 from '../assets/images/home/Img1.webp';
import Img2 from '../assets/images/home/Img2.webp';
import InquiryFormHome from '../components/InquiryFormHome';
import SingleService from '../components/common/SingleService';
import SingleService2 from '../components/common/SingleService2';
import ServIcon1 from '../assets/images/home/1.webp';
import ServIcon2 from '../assets/images/home/2.webp';
import ServIcon3 from '../assets/images/home/3.webp';
import ServIcon4 from '../assets/images/home/4.webp';
import ServIcon5 from '../assets/images/home/5.webp';
import ServIcon6 from '../assets/images/home/6.webp';
import ServIcon7 from '../assets/images/home/7.webp';
import ServIcon8 from '../assets/images/home/8.webp';
import ServIcon9 from '../assets/images/home/9.webp';
import ServIcon10 from '../assets/images/home/10.webp';
import Icon1 from '../assets/images/home/Icon1.png';
import Icon2 from '../assets/images/home/Icon2.png';
import Icon3 from '../assets/images/home/Icon3.png';
import MiddleBannerImage from '../assets/images/home/MiddleBg.webp';
import MiddleBanner from '../components/common/MiddleBanner';
import Video1 from '../assets/images/home/Video1.mp4';
import Video2 from '../assets/images/home/Video2.mp4';
import Testimonials from '../components/Testimonials';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])

  return (
    <>
      <MetaTags>
        <title> Non Surgical Medical Weight Loss Clinic - Safe & Effective  </title> 
        <meta name="description" content="Seeking non-surgical weight loss solutions? Find personalized plans for lasting results with experienced MD." />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Non Surgical Medical Weight Loss Clinic - Safe & Effective " />
        <meta property="og:description" content="Seeking non-surgical weight loss solutions? Find personalized plans for lasting results with experienced MD." />
        <meta property="og:url" content="https://northshoredigestivemedicinepc.com/" />
        <meta property="og:site_name" content="NSDM" />
        <meta property="og:image" content="https://northshoredigestivemedicinepc.com/og.webp" />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
        <meta property="og:image:secure_url" content="https://northshoredigestivemedicinepc.com/og.webp" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content="Seeking non-surgical weight loss solutions? Find personalized plans for lasting results with experienced MD." />
        <meta name="twitter:title" content="Non Surgical Medical Weight Loss Clinic - Safe & Effective " />
        <meta name="twitter:image" content="https://northshoredigestivemedicinepc.com/og.webp"/>
      </MetaTags>
    <Header/>
    <section className="container-fluid text-center text-lg-start text-avenir">
      <div className="row justify-content-center align-items-center py-5 gy-5">
        <div className="col-11 col-lg-6 lh-sm ps-lg-5 ms-lg-5">
          <h1 className="text-ss-primary fw-500 fs-ss-sub-title text-uppercase">
            Non-surgical & <br className='d-block d-lg-none' /> longer-lasting <br className='' />
            <div className='mt-3 mt-md-4 mt-lg-3'/>
            <span className='fs-ss-title text-ss-secondary fw-600 '> weight loss <br className='d-none d-lg-block' /> solutions </span>
          </h1>
          <div id='btn' className="pt-lg-5 pt-4">
          <Link to="/book-an-appointment" className='text-decoration-none py-md-2 py-1 fw-500 btn-transparent border-0 text-ss-secondary rounded-5 btn-book px-3'>
            Book an Appointment
          </Link>          
          
          </div>
        </div>
        <div className="col-11 col-md-8 col-lg-5 px-lg-4">
        <img className='w-100 px-lg-5' src={Img1} alt="" />
        </div>
      </div>
    </section>

    <section className='pt-2 pt-md-0'/>
    <section className='pt-md-4'/>

    <section id='website-responsive' className="container-lg text-ss-secondary text-lg-center">
      <div className="row justify-content-center">
        <div className="col-11">
          <div className=''>
            <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            North Shore Digestive Medicine
            </h2>
            <h3 className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm"> Your Perfect Weight Loss Journey Starts Here  </h3>
            <div className='pt-md-4 pt-3'/>
            <div className='fs-ss-16 fw-400 lh-para'>
              Welcome to North Shore Digestive Medicine, where we aim to provide competent compassionate care. We specialize in safe, non-surgical weight loss solutions designed for those seeking effective alternatives to surgery. Our procedures, including the advanced Endoscopic Sleeve Gastroplasty (ESG), Orbera Balloon, and Gastric Revisions are straightforward, require minimal downtime, and leave no scars, ensuring you can quickly return to your daily life without any physical reminders of the procedure.
              <div className='py-2'/> 
              At North Shore Digestive Medicine, your safety and well-being are at the forefront of everything we do. Our dedicated team at North Shore Digestive Medicine offers personalized care backed by the latest research, nutritional counseling, and a commitment to excellence. Trust in our skilled team to guide you through a smooth weight loss journey toward lasting success because your first step to a healthier, more confident you begins here.           
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-5 mt-md-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
          <div className='text-center'>
          <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
          WEIGHT LOSS PROCEDURES
          </h2>
            <h3 className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm"> Your gateway to a healthier you   </h3>
          </div>
      <div className="row justify-content-center text-center pt-4 pt-lg-5 gy-4">
        
      <SingleService
        Image={ServIcon2}
        Para={<> It's an innovative weight loss procedure without surgery that offers effective results.<br/> <br/> </>}
        Title="ESG"
        Link_To="esg-long-island-ny"
        />
        
        <SingleService
        Image={ServIcon1}
        Title="Orbera Balloon"
        Link_To="orbera-balloon-long-island-ny"
        Para={<> Our revolutionary, non-surgical procedure aids significant weight loss with minimal invasiveness. <br/> <br/> </>}
        Space="mx-md-2 mx-lg-5"
        />

        <SingleService
        Image={ServIcon3}
        Title="Gastric Revisions"
        Link_To="gastric-revisions-long-island-ny"
        Para="Gastric revisions are non-surgical procedures for weight loss that reduces the size of the stomach and enhances feelings of fullness. "
        />        
      </div>
    </section>
    
    <section className='pt-5 mt-md-5 mt-3'/>

    <section id='website-responsive' className="container-lg text-ss-secondary text-lg-center bg-blue-pattern2">
    <div className="row justify-content-center">
        <div className="col-11">
          <div className=''>
          <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
          Endoscopic Sleeve Gastroplasty
          </h2>
            <h3 className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm"> Your Path to Significant Weight Loss, Scar-Free Procedure  </h3>
            <div className='pt-md-4 pt-3'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            ESG is an effective weight loss procedure that can help individuals lose weight without surgery. Unlike traditional bariatric surgeries such as gastric bypass, ESG does not require any incisions, resulting in less pain, fewer complications, and a faster recovery time. The stomach is reduced in size by 60-75% using an endoscopic suturing device without the need for surgical incisions. 
            </div>
            </div>
          </div>
          </div>
          <div className="row justify-content-center text-center pt-5">
            <div className="col-11 col-lg-7 bg-grey-2 px-2 pt-2 rounded-3 shadow-ss-1 btn-black">
              <video className='w-100 rounded-3' src={Video2} autoPlay={true} loop="true" muted controls />
            </div>
            </div>      
    </section>


    <section className='pt-5 mt-md-5'/>

    <MiddleBanner
    Image={MiddleBannerImage}
    />

    <section className='pt-5 mt-md-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary text-lg-center bg-blue-pattern">
    <div className="row justify-content-center">
        <div className="col-11">
          <div className=''>
          <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
          ORBERA BALLOON 
          </h2>
            <h3 className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm"> Achieve Your Weight Loss Goals with Orbera Balloon  </h3>
            <div className='pt-md-4 pt-3'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            The Orbera balloon is a non-surgical and revolutionary procedure designed to assist individuals in achieving significant weight loss. It is a 15-minute procedure that can help you lose, on average, 10% of your total body weight. Moreover, it leaves no scars and is removed endoscopically after six months. We help individuals achieve significant weight loss through a gastric balloon, personalized coaching, and ongoing support.  
            </div>
          </div>
          </div>
          </div>
          <div className="row justify-content-center text-center pt-5">
            <div className="col-11 col-lg-7 bg-grey-2 px-2 pt-2 rounded-3 shadow-ss-1 btn-black">
              <video className='w-100 rounded-3' src={Video1} autoPlay={true} loop="true" muted controls />
            </div>
            </div>      
    </section>

    <section className='pt-5 mt-md-5'/>
    
    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className='text-center'>
      <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
      Other DIGESETIVE PROCEDURES 
      </h2>
        {/* <h3 className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm"> Surgical Weight Loss Program </h3> */}
      </div>

      <div className="row justify-content-center text-center pt-4 pt-md-5 gy-4">
        <SingleService2
        Image={ServIcon4}
        Title="Colonoscopy"
        Link_To="colonoscopy"
        Space2="px-3 px-md-0"
        />

        <SingleService2
        Image={ServIcon5}
        Title={<> Upper Endoscopy <br /> (EGD) </>}
        Link_To="upper-endoscopy"
        Space=""
        Space2="px-3 px-md-0"
        />
        
        <SingleService2
        Image={ServIcon7}
        Title="ERCP"
        Link_To="ercp"
        Space2="px-3 px-md-0"
        />     
        
        <SingleService2
        Image={ServIcon10}
        Title={<> Radio Frequency <br /> Ablation Therapy </>}
        Link_To="radio-frequency-ablation-therapy"
        Space2="px-3 px-md-0"
        Space="d-block d-md-none"
        />              
      </div>
      
      <div className="row justify-content-center text-center pt-4 pt-lg-5 gy-4">
        <SingleService2
        Image={ServIcon8}
        Title={<> CapsoVision <br /> Endoscopy </>}
        Link_To="capsovision-endoscopy"
        Space2="px-3 px-md-0"
        />

        <SingleService2
        Image={ServIcon9}
        Title="PEG"
        Link_To="peg"
        Space=""
        Space2="px-3 px-md-0"
        />

        <SingleService2
        Image={ServIcon10}
        Title={<> Radio Frequency <br /> Ablation Therapy </>}
        Link_To="radio-frequency-ablation-therapy"
        Space2="px-3 px-md-0"
        Space="d-none d-md-block"
        />   
      </div>
    </section>

    <section className='pt-5 mt-md-5'/>

    <section id='website-responsive' className="container-fluid bg-light py-5">
      <div className="row justify-content-center align-items-center gy-5">
        <div className="col-11 col-lg-5 text-center d-none d-lg-block"> 
        <img className='w-75 ' src={Img2} alt="" />        
        </div>       
        <div className="col-11 col-lg-5">
        <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase pe-lg-5'>
          {/* ESG, Orbera Balloon and Gastric Revisions in Long Island */}
          Weight Loss Procedures in Long Island, NY
        </h2>
            <div className='pt-md-4 pt-3'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            We offer a unique approach to weight loss. Our procedures and caring team help you transform your life. Whether it's ESG, Orbera Balloon, or Gastric Revisions, we're here to support you at every step.             
            <div className='py-2'/>
            Our non-surgical procedures help lose weight and improve fatty liver, control high cholesterol and high blood pressure. So, say goodbye to fad diets and temporary fixes – As a weight loss clinic in New York, we'll help you become a healthier, happier person. 
            </div>
            <div className="pt-4">
          <Link to="/book-an-appointment" className='text-decoration-none py-md-2 py-1 fw-500 btn-transparent-dark text-ss-secondary rounded-5 fs-ss-16 px-3 border-dark border-1 border'>
            Book an Appointment
          </Link>          
          </div>
        </div>
        <div className="col-11 col-md-8 col-lg-5 text-center d-block d-lg-none"> 
        <img className='w-75' src={Img2} alt="" />        
        </div>
      </div>
    </section>

    <section className='pt-4 mt-md-5'/>
    <section className='pt-md-5'/>

    <section id='website-responsive' className="container-fluid">
    <div className="row justify-content-center gy-4">
    <div className="col-11 col-md-3 text-center">
    <img class="w-logo" src={Icon1} alt=""/>
      <div className="fs-ss-40 fw-600 text-ss-secondary py-3">
        30+
      </div>
      <div className="fs-ss-20 fw-500 lh-sm">
        Years of Services
      </div>    
    </div>

    <div className="col-11 col-md-3 text-center">
    <img class="w-logo2" src={Icon2} alt=""/>
      <div className="fs-ss-40 fw-600 text-ss-secondary py-3">
        40,000+
      </div>
      <div className="fs-ss-20 fw-500 lh-sm">
        Procedures Performed
      </div>    
    </div>

    <div className="col-11 col-md-3 text-center">
    <img class="w-logo" src={Icon3} alt=""/>
      <div className="fs-ss-24 fw-700 text-ss-secondary text-uppercase lh-sm py-3">
        Too many <br /> to count
      </div>
      <div className="fs-ss-20 fw-500 lh-sm">
        Happy Clients
      </div>    
    </div>
    </div>
    </section>
    
    <section className='pt-5 mt-md-5'/>
    <section className='pt-md-5'/>

    <section id='website-responsive' className="container-fluid bg-commas px-4">
        <div className="text-center">
        <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
          CLIENT TESTIMONIALS
          </h2> 
          <h3 className="text-ss-primary fw-500 text-capitalize fs-ss-22"> It's always the word of mouth that's the best advice </h3>
        </div>
      <div className="container-fluid">
      <div className="row justify-content-center align-items-center pt-4 pt-md-5 mt-lg-4">
      <div className="col-12 col-md-10">
        <Testimonials/>
      </div>
      </div>
      </div>
    </section>

    <section className='pt-5 mt-md-5'/>
    <section className='pt-5'/>


    <section id='website-responsive' className="container-lg">
    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-center align-items-center bg-light px-0 rounded-4 rounded-start shadow-ss-1">


      <div className="col-12 col-md-6 rounded-start-4 bg-form shadow-ss-1 text-center vh-80 d-none d-md-block">
        <div className="py-5 my-4 my-md-5" />
        <h2 className="fw-600 fs-ss-40 lh-sm px-4">
        Weight Loss Clinic in <br/> Long Island, NY
        </h2>
        <div className="pt-4 pt-md-5">
          <Link to="/book-an-appointment" className='text-decoration-none py-md-2 py-1 fw-500 btn-dark-transparent rounded-5 fs-ss-16 px-3 border-dark border-1 border'>
            Book an Appointment
          </Link>          
          </div>
        </div>


        <div className="col-12 bg-form shadow-ss-1 py-5 rounded-top-4 text-center d-block d-md-none">
        <h2 className="fw-600 fs-ss-40 lh-sm px-4">
        Weight Loss Clinic in <br/> Long Island, NY
        </h2>
        <div className="pt-4 pt-md-5">
          <Link to="/book-an-appointment" className='text-decoration-none py-md-2 py-1 fw-500 btn-dark-transparent rounded-5 fs-ss-16 px-3 border-dark border-1 border'>
            Book an Appointment
          </Link>          
          </div>
        </div>



        <div id='form-home' className="col-11 col-md-6 py-5">
          <div className='col-11 col-lg-9 mx-auto'>
            <div className='fs-ss-30 fw-600 lh-sm text-ss-secondary text-uppercase text-center'>
              Working Hours
            </div>
            <div className='pt-4'/>
            <div className='fs-ss-16 fw-500 lh-sm'>
            <table className="table">
              <tbody>
                <tr className=''>
                  <td className=' pt-3'> Mon: </td>
                  <td className='text-end pt-3'> 8:00 AM - 4:30 PM </td>
                </tr>

                <tr>
                  <td className=' pt-3'> Tues: </td>
                  <td className='text-end pt-3'> 8:00 AM - 3:00 PM </td>
                </tr>

                <tr>
                  <td className=' pt-3'> Wed: </td>
                  <td className='text-end pt-3'> 8:00 AM - 3:00 PM </td>
                </tr>

                <tr>
                  <td className=' pt-3'> Thur: </td>
                  <td className='text-end pt-3'> 8:00 AM - 3:00 PM </td>
                </tr>

                <tr>
                  <td className=' pt-3'> Fri: </td>
                  <td className='text-end pt-3'> 9:00 AM - 2:00 PM </td>
                </tr>

                <tr>
                  <td className=' pt-3'> Sat - Sun </td>
                  <td className='text-end pt-3'> Closed </td>
                </tr>
              </tbody>
            </table>
            </div>
          </div>
        </div>
      </div>
    </section>
    </section>
    
    <section className='pt-5 mt-md-5'/>
    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12071.410090229168!2d-73.188156!3d40.8531616!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e83a193a1b0cf5%3A0x5b018789218a786!2sNorth%20Shore%20Digestive%20Medicine%2C%20Pc!5e0!3m2!1sen!2s!4v1713440610627!5m2!1sen!2s" height="410" allowfullscreen="" loading="lazy" className='w-100 shadow-ss-inner border-bottom-grey' referrerpolicy="no-referrer-when-downgrade"></iframe>
    <Footer/>
    </>
  );
}

export default Home;
