import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import Img1 from '../../assets/images/ercp/Img1.webp';
import MiddleBannerImage from '../../assets/images/ercp/MiddleBg.webp';
import { Link } from 'react-router-dom';
import FAQsSection from '../../components/FAQsSection';
import CommonBanner from '../../components/CommonBanner';
import BannerImg from '../../assets/images/ercp/BannerImg.webp';
import { CircleFill } from 'react-bootstrap-icons';
import Bullets from '../../components/common/Bullets';
import { MetaTags } from 'react-meta-tags';

const BreathTests = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
      <MetaTags>
        <title> Best ERCP Doctors in Long Island, NY </title> 
        <meta name="description" content="Trust our experienced MD for your ERCP procedure in Long Island, NY. NSDM offers reliable diagnosis and treatment for your digestive health needs." />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Best ERCP Doctors in Long Island, NY" />
        <meta property="og:description" content="Trust our experienced MD for your ERCP procedure in Long Island, NY. NSDM offers reliable diagnosis and treatment for your digestive health needs." />
        <meta property="og:url" content="https://northshoredigestivemedicinepc.com/" />
        <meta property="og:site_name" content="NSDM" />
        <meta property="og:image" content="https://northshoredigestivemedicinepc.com/og.webp" />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
        <meta property="og:image:secure_url" content="https://northshoredigestivemedicinepc.com/og.webp" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content="Trust our experienced MD for your ERCP procedure in Long Island, NY. NSDM offers reliable diagnosis and treatment for your digestive health needs." />
        <meta name="twitter:title" content="Best ERCP Doctors in Long Island, NY" />
        <meta name="twitter:image" content="https://northshoredigestivemedicinepc.com/og.webp"/>
      </MetaTags>
    <Header/>

    <CommonBanner
    Title="ERCP"
    Image={BannerImg}
    />
    
    <section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary text-lg-center">
      <div className="row justify-content-center">
        <div className="col-11">
          <div className=''>
            <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            ERCP
            </h2>
            <h3 className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm pt-2"> (Endoscopic Retrograde Cholangiopancreatography) </h3>
            <div className='pt-4'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            ERCP is a sophisticated diagnostic and therapeutic procedure scrutinizing the bile, pancreatic, and gallbladder. This minimally invasive technique utilizes an endoscope a flexible tube to navigate through the mouth, esophagus, stomach, and duodenum, reaching the targeted ducts. Contrast dye is then administered through a catheter to enhance visualization, allowing for detecting abnormalities such as gallstones, tumors, or strictures.
            <div className='py-2'/> 
            ERCP is diagnostic and therapeutic, enabling interventions like gallstone removal or stent placement to alleviate obstructions. This intricate procedure necessitates the expertise of a gastroenterologist trained in ERCP, typically performed under sedation or anesthesia.
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>
    
    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-evenly align-items-center gy-md-4 ">
        <div className="col-11 col-lg-6">
          <div className=''>
          <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            Benefits of ERCP
            </h2>
            <div className='pt-4'/>
            <div className='fs-ss-18 fw-500 lh-para'>
            <Bullets
            Title="Comprehensive Evaluation"
            Para="ERCP thoroughly examines the bile and pancreatic ducts, enabling diagnosis of various disorders."
            />
            <Bullets
            Title="Diagnostic Precision"
            Para="Using contrast dye and X-ray imaging, ERCP facilitates accurately identifying abnormalities such as gallstones, tumors, or strictures."
            />
            <Bullets
            Title="Therapeutic Interventions"
            Para="In addition to diagnosis, ERCP allows for therapeutic interventions, including gallstone removal and stent placement, to alleviate obstructions."
            />
            <Bullets
            Title="Minimally Invasive"
            Para="Despite its complexity, ERCP is minimally invasive, minimizing patient discomfort and promoting faster recovery."
            />
            <Bullets
            Title="Expertise and Specialization"
            Para="ERCP is performed by gastroenterologists with specialized training and expertise in the procedure, ensuring optimal patient outcomes."
            />                                                      
            </div>
          </div>
        </div>
        <div className="col-10 col-lg-5">
        <img className='w-100 px-lg-5' src={Img1} alt="" />        
        </div>
      </div>
    </section>

    <section className='pt-md-5 pt-3 mt-lg-5'/>

    <section id='website-responsive' className="container-fluid bg-digestive bg-digestive4 py-5 row justify-content-center gx-0">
    <section className="container-lg text-ss-secondary text-lg-center py-5">
      <div className="row justify-content-center">
        <div className="col-11">
          <div className=''>
            <h2 className='fs-ss-40 fw-600 lh-sm text-black'>
            Expert Care, Exceptional Results with ERCP
            </h2>
            <div className="pt-4 pt-lg-5">
            <div className='fs-ss-18 fw-500 lh-para'>
            If you'd like to learn more about procedures or to <br className='d-none d-md-block'/> find out if you are a fit candidate or not, please contact us!
            </div>
            <div className='pt-4 pt-lg-5'/>
          <Link to="/book-an-appointment" className='text-decoration-none py-md-2 py-1 fw-500 btn-transparent-dark text-ss-secondary rounded-5 fs-ss-16 px-3 border-dark border-1 border'>
            Book an Appointment
          </Link>          
          </div>
            </div>
          </div>
        </div>   
    </section>
    </section>

    <section className='pt-5 mt-lg-4'/>

    <section id='website-responsive' className="container-lg text-ss-secondary text-lg-center">
      <div className="row justify-content-center">
        <div className="col-11">
          <div className=''>
            <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            Your Path to Digestive Wellness <br className='d-none d-md-block'/> Starts Here
            </h2>
            <div className='pt-4'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            North Shore Digestive Medicine offers ERCP in Long Island and effective treatment for gastrointestinal disorders. Our experienced gastroenterologists specialize in performing ERCP procedures with precision and care, utilizing advanced techniques to ensure optimal outcomes for our patients. If you require an ERCP procedure or have concerns about your digestive health, schedule a consultation with us today. Let us help you achieve optimal gastrointestinal health and well-being.
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-4'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
    <div className='fs-ss-30 fw-600 fw-600 lh-sm text-center pb-4'>
      FAQS
      </div>
      <div className="row justify-content-center text-center">
        <div className="col-11 col-lg-11">
          <FAQsSection
          Title="Is ERCP painful? "
          Para="ERCP is typically performed under sedation or anesthesia, ensuring patient comfort during the procedure."
          />

          <FAQsSection
          Title="How long does an ERCP procedure take? "
          Para="The duration of an ERCP procedure depends on factors such as the complexity of the case and any therapeutic interventions required. It may take between 15 to 90 minutes."
          />

          <FAQsSection
          Title="What are the risks associated with ERCP? "
          Para="While ERCP is generally safe, there are risks involved, including pancreatitis, infection, bleeding, perforation of the gastrointestinal tract, and adverse reactions to sedation. Your healthcare expert provider will discuss these risks with you before the procedure."
          />

          <FAQsSection
          Title="How should I prepare for an ERCP? "
          Para="Preparation for an ERCP typically involves fasting for a certain period before the procedure and following specific instructions provided by your healthcare provider. You may also need to discontinue certain medications temporarily."
          />

          <FAQsSection
          Title="When is ERCP recommended? "
          Para="ERCP is recommended for various conditions affecting the bile and pancreatic ducts, including gallstones, tumors, strictures, and pancreatic disorders. Your healthcare provider will determine if ERCP is appropriate for your situation based on your symptoms and diagnostic findings."
          />
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>

    <Footer/>
    </>
  );
}

export default BreathTests;
