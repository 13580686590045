import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BannerImg from '../assets/images/BMI.webp';
import CommonBanner from '../components/CommonBanner';
import BMI2 from '../components/common/BMI2';

const BMICalculator = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
      <title>
      BMI Calculator - North Shore Digestive Medicine
      </title>
    </Helmet>
    <Header/>

    <CommonBanner
    Title="BMI Calculator"
    Image={BannerImg}
    />
    
    <section className='pt-5 mt-lg-5'/>

   <section className="row justify-content-center container-fluid text-center mx-auto">
    <div className="col-11 col-md-8">
    <div className='fs-ss-32 fw-600 lh-sm text-uppercase pb-3'>
      CALCULATE YOUR BMI
    </div>
    <div className="fs-ss-16 fw-400 pb-4 pb-md-5">
      The BMI calculator is a crucial tool in the fight against obesity as it helps determine a patient’s body mass and ideal weight. Accurate results are obtained by taking into account the patient’s age, height, and weight when calculating both actual and ideal values.
    </div>
    </div>
    <div className="row justify-content-center ">
      <div className="col-11 col-md-5 col-lg-3 border border-dark bg-primary rounded-2 py-4 px-5">
        <BMI2/>
      </div>
    </div>
   </section>
    <section className='pt-5 mt-lg-5'/>

    <Footer/>
    </>
  );
}

export default BMICalculator;
