import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap'
import '../src/assets/css/style.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import 'react-tabs/style/react-tabs.css';

// Pages
import Home from './pages/Home'
import OrberaBalloon from './pages/OrberaBalloon'
import ESG from './pages/ESG';
import GastricRevisions from './pages/GastricRevisions';
import WeightLoss from './pages/WeightLoss';
import OurTeam from './pages/OurTeam';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ContactUs from './pages/ContactUs';
import FAQs from './pages/FAQs';
import LandingPage from './pages/LandingPage';
import BMICalculator from './pages/BMICalculator';
import FinancingAndInsurance from './pages/FinancingAndInsurance';
import AboutUs from './pages/AboutUs';
import ESGLandingPage from './pages/ESGLandingPage';
import BookAnAppointment from './pages/BookAnAppointment';
// import BreathTests from './pages/BreathTests';
import Colonoscopy from './pages/digestive/Colonoscopy';
import CapsoVisionEndoscopy from './pages/digestive/CapsoVisionEndoscopy';
import ERCP from './pages/digestive/ERCP';
import PEG from './pages/digestive/PEG';
import RadioFrequencyAblation from './pages/digestive/RadioFrequencyAblation';
import UpperEndoscopy from './pages/digestive/UpperEndoscopy';

import GastricRevisionsLandingPage from './pages/GastricRevisionsLandingPage';
import OrberaLandingPage from './pages/OrberaLandingPage';
import Digestive from './pages/Digestive';
import SuccessStories from './pages/SuccessStories';
import ESGLandingPage2 from './pages/ESGLandingPage';
import Error from './pages/Error';
import ThankYou from './pages/ThankYou';
import Education from './pages/Education';

const App = () => {
  return (
    <>
        <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/about-us" element={<AboutUs/>} />
        <Route path="/our-team" element={<OurTeam/>} />
        <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
        <Route path="/bmi-calculator" element={<BMICalculator/>} />
        <Route path="/education" element={<Education/>} />
        <Route path="/financing-and-insurance" element={<FinancingAndInsurance/>} />
        <Route path="/book-an-appointment" element={<BookAnAppointment/>} />
        <Route path="/contact-us" element={<ContactUs/>} />
        <Route path="/landing-page" element={<LandingPage/>} />
        {/* <Route path="/breath-tests" element={<BreathTests/>} /> */}
        <Route path="/colonoscopy" element={<Colonoscopy/>} />
        <Route path="/capsovision-endoscopy" element={<CapsoVisionEndoscopy/>} />
        <Route path="/ercp" element={<ERCP/>} />
        <Route path="/peg" element={<PEG/>} />
        <Route path="/radio-frequency-ablation-therapy" element={<RadioFrequencyAblation/>} />
        <Route path="/upper-endoscopy" element={<UpperEndoscopy/>} />
        <Route path="*" element={<Error/>} />
        <Route path="/thank-you" element={<ThankYou/>} />
        <Route path="/esg" element={<ESGLandingPage/>} />
        <Route path="/faqs" element={<FAQs/>} />
        <Route path="/esg-long-island-ny" element={<ESG/>} />
        <Route path="/orbera-balloon-long-island-ny" element={<OrberaBalloon/>} />
        <Route path="/orbera-gastric-balloon" element={<OrberaLandingPage/>} />
        <Route path="/gastric-revisions-long-island-ny" element={<GastricRevisions/>} />
        <Route path="/gastric-revisions" element={<GastricRevisionsLandingPage/>} />
        <Route path="/non-surgical-weight-loss-long-island-ny" element={<WeightLoss/>} />
        <Route path="/digestive-procedures" element={<Digestive/>} />
        <Route path="/success-stories" element={<SuccessStories/>} />

        <Route path='/about-us/procedures' element={ <Navigate to="/non-surgical-weight-loss-long-island-ny" /> }/>
        <Route path='/2017/09/03/timing-of-melanoma-diagnosis-treatment-critical-to-survival' element={ <Navigate to="/contact-us" /> }/>
        <Route path='/bmi-calculators' element={ <Navigate to="/bmi-calculator" /> }/>
        <Route path='/shop/shop-single' element={ <Navigate to="/" /> }/>
        <Route path='/home' element={ <Navigate to="/" /> }/>
        <Route path='/photo-tour' element={ <Navigate to="/" /> }/>

        <Route path='/site' element={ <Navigate to="/" /> }/>
        <Route path='/appointments' element={ <Navigate to="/book-an-appointment" /> }/>
        <Route path='/shop' element={ <Navigate to="/" /> }/>
        <Route path='/services' element={ <Navigate to="/non-surgical-weight-loss-long-island-ny" /> }/>
        <Route path='/radiofrequency-ablation' element={ <Navigate to="/radio-frequency-ablation-therapy" /> }/>
        <Route path='/site/bmi-calculate' element={ <Navigate to="/bmi-calculator" /> }/>
        <Route path='/bmi-calculate' element={ <Navigate to="/bmi-calculator" /> }/>
        <Route path='/site/breath-tests' element={ <Navigate to="/digestive-procedures" /> }/>
        <Route path='/site/about-us' element={ <Navigate to="/about-us" /> }/>
        <Route path='/endoscopic-sleeve-gastroplasty' element={ <Navigate to="/esg-long-island-ny" /> }/>
        <Route path='/fast-track' element={ <Navigate to="/" /> }/>
        <Route path='/portfolio/breast-augmentation' element={ <Navigate to="/" /> }/>
        <Route path='/portfolio/liposculpture' element={ <Navigate to="/" /> }/>







        </Routes>
    </>
  );
}

export default App;
