import React from 'react';
import { TelephoneFill } from 'react-bootstrap-icons';
import {Link} from 'react-router-dom';

const MiddleBanner = (props) => {
  return (
    <>
    <section id='website-responsive' className="container-fluid bg-middle-banner py-5" style={{backgroundImage:`url(${props.Image})`}}>
      <div className="row justify-content-center py-lg-5">
        <div className="col-5 col-lg-6"/>        
        <div className="col-7 col-lg-6 pt-lg-5 mt-lg-5 mt-4">
          <h2 className='fw-700 text-white fs-ss-middle-banner text-uppercase lh-sm'>
            Drop The <br className='d-none d-lg-block' /> Inches, Boost <br className='d-none d-lg-block' /> Your Energy
          </h2>
          <div id='btn' className="pt-3 pt-lg-5">
          <a href="tel:+16319978135" className='text-decoration-none py-2 fw-500 btn-middle-banner2 rounded-5 fs-ss-18 px-4 border-white border-1 border'>
         <TelephoneFill className='me-2'/> 631-997-8135
          </a>          
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default MiddleBanner;