import React, { Component, useState } from "react";
import Slider from "react-slick";
import { StarFill } from "react-bootstrap-icons";
import BgRev from '../assets/images/home/BgReviews.webp';


const Testimonials = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        }
      },
    ]
  };

  const Data = [
    {
      title: "PAUL S",
      link: "" ,
      para: "I (and my wife) have been seeing Dr. Forohar for 15+ years. I have never felt under serviced or rushed during an appoint- ment. He explains everything in detail and answers any questions or concerns you may have. Highly Recommended",

    },

    {
      title: "TERRI MILLIGAN",
      link: "" ,
      para: "My entire family see Dr. Forohar for all gastrointestinal needs. I recently needed to see him for a specific issue that had put me in the ER a few days prior. Dr. Forohar is kind, respectful and very knowledgeable. He explains everything very well and speaks to you directly and precisely.",
      
    },

    {
      title: "MARIA DEGENNARO",
      link: "" ,
      para: "Dr. Forohar literally saved my life by not giving up until I agreed to have a colonoscopy. I've been to many doctor's offices as I've gotten older, and Dr. Forohar's staff is the best I've seen. They are knowledgeable, efficient, compassionate and extremely accommodating.",
      
    },
  ]

  return (
    <>
        <section className="container-fluid">
        <Slider {...settings}>
    {
      Data.map((Data) => {
        return (
        <>
          <div className="row justify-content-center col-12 mx-auto bg-reviews vh-max-content pt-lg-4 d-none d-lg-flex">
            <div className="col-10 col-lg-8">
          <a className="text-decoration-none text-ss-secondary hover-pri" href={Data.link} target="_blank">
            <div className="fs-ss-22 fw-600 fs-ss-reviews-title">
              {Data.title}
            </div>
            <div className="py-2 text-ss-secondary fw-500 fs-ss-reviews-subtitle">
            {Data.para}
            </div>
            </a>
            </div>
          </div>

          <div className="row justify-content-center col-12 mx-auto bg-light p-4 rounded-5 vh-max-content pt-lg-4 d-block d-lg-none">
            <div className="col-12">
          <a className="text-decoration-none text-ss-secondary hover-pri" href={Data.link} target="_blank">
            <div className="fs-ss-22 fw-600 fs-ss-reviews-title">
              {Data.title}
            </div>
            <div className="py-2 text-ss-secondary fw-500 fs-ss-reviews-subtitle">
            {Data.para}
            </div>
            </a>
            </div>
          </div>
        </>
        )
      })
    }
            </Slider>
      </section>
      </>
    );
  }

export default Testimonials;
