import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BannerImg from '../assets/images/weight-loss/BannerImg.webp';
import Img1 from '../assets/images/landing-page/Img1.webp';
import Img2 from '../assets/images/landing-page/Img2.webp';
import Video1 from '../assets/images/home/Video1.mp4';
import CommonBanner from '../components/CommonBanner';
import ServIcon1 from '../assets/images/weight-loss/2.webp';
import ServIcon2 from '../assets/images/weight-loss/1.webp';
import ServIcon3 from '../assets/images/weight-loss/3.webp';
import { Link } from 'react-router-dom';
import Icon1 from '../assets/images/home/Icon1.png';
import Icon2 from '../assets/images/home/Icon2.png';
import Icon3 from '../assets/images/home/Icon3.png';
import Testimonials from '../components/Testimonials';

const LandingPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
      <title>
      Landing Page - North Shore Digestive Medicine
      </title>
    </Helmet>
    <Header/>

    <section className="container-fluid text-center text-lg-start text-avenir">
      <div className="row justify-content-center align-items-center py-5 gy-5">
        <div className="col-11 col-lg-6 lh-sm ps-lg-5 ms-lg-5">
          <div className="text-ss-primary fw-700 fs-ss-52 text-ss-secondary">
            Your Perfect <br /> <span className='text-ss-primary'> Weight Loss </span> Journey <br /> Starts Here
          </div>
          <div id='btn' className="pt-lg-5 pt-4">
          <Link to="/book-an-appointment" className='text-decoration-none py-md-2 py-1 fw-500 btn-orange border-0 text-ss-secondary rounded-5 px-3'>
            Book an Appointment
          </Link>          
          </div>
        </div>
        <div className="col-11 col-md-8 col-lg-5">
        <img className='w-100' src={Img1} alt="" />
        </div>
      </div>
    </section>

<section className='pt-5 mt-lg-5'/>

<section id='website-responsive' className="container-lg text-ss-secondary text-lg-center">
      <div className="row justify-content-center">
        <div className="col-11">
          <div className=''>
            <div className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            Trim Your Waistline, <br className='' /> 
            <div className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm pt-2"> Skip the Surgery with Our Non-Surgical Procedures </div>
            </div>
          </div>
        </div>
        <div className='pt-5'/>
        <div className="row justify-content-center text-center">
        <div className="col-serv py-4 px-4">
      <Link className='text-decoration-none text-ss-secondary' to="/esg">
      <img className='w-services2 me-4' src={ServIcon1} alt="" />
      <div className="text-start">
      <div className='fw-600 pt-3 fs-ss-18 lh-sm text-ss-primary'>
        ESG
      </div>
      <div className='fw-500 pt-3 fs-ss-14'>
        It's an innovative weight loss procedure without surgery that offers effective results. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
      <div className='fw-400 pt-4 fs-ss-16 lh-sm'>
        <span className='text-ss-secondary fs-ss-12 border border-1 border-dark rounded-5 px-2 fw-500'> Find Out more </span>
      </div>
      </div>
      </Link>
    </div>
    
    <div className="col-serv py-4 px-4 mx-md-2 mx-lg-4">
      <Link className='text-decoration-none text-ss-secondary' to="/orbera-balloon">
      <img className='w-services2 me-4' src={ServIcon2} alt="" />
      <div className="text-start">
      <div className='fw-600 pt-3 fs-ss-18 lh-sm text-ss-primary'>
      Orbera Balloon
      </div>
      <div className='fw-500 pt-3 fs-ss-14'>
      Our revolutionary, nonsurgical procedure aids significant weight loss with minimal invasiveness.
      </div>
      <div className='fw-400 pt-4 fs-ss-16 lh-sm'>
        <span className='text-ss-secondary fs-ss-12 border border-1 border-dark rounded-5 px-2 fw-500'> Find Out more </span>
      </div>
      </div>
      </Link>
    </div>

    <div className="col-serv py-4 px-4">
      <Link className='text-decoration-none text-ss-secondary' to="/gastric-sleeve">
      <img className='w-services2 me-4' src={ServIcon3} alt="" />
      <div className="text-start">
      <div className='fw-600 pt-3 fs-ss-18 lh-sm text-ss-primary'>
      Gastric Sleeve
      </div>
      <div className='fw-500 pt-3 fs-ss-14'>
      A surgical option for weight loss, reducing stomach size, and promoting satiety. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
      <div className='fw-400 pt-4 fs-ss-16 lh-sm'>
        <span className='text-ss-secondary fs-ss-12 border border-1 border-dark rounded-5 px-2 fw-500'> Find Out more </span>
      </div>
      </div>
      </Link>
    </div>   
        </div>
      </div>
    </section>

<section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-center align-items-center gy-4 ">
        <div className="col-11 col-lg-10">
          <div className=''>
            <div className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            WHY NORTHSHORE
            </div>
            <div className='pt-4'/>
            <ul className='fs-ss-18 fw-600 lh-para ps-3'>
            <li> Non-surgical weight loss procedures </li>
            <div className="py-2" />
            <li> Lose 18%-20% of your body weight on average </li>
            <div className="py-2" />
            <li> Personalized Care and individualized treatment plans </li>
            <div className="py-2" />
            <li> Experienced medical professionals </li>
            <div className="py-2" />
            <li> Advanced Weight loss procedures with Comprehensive Support and Proven Results </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>
    

    <section id='website-responsive' className="container-fluid">
    <div className="row justify-content-center gy-4">
    <div className="col-11 col-md-3 text-center">
    <img class="w-logo" src={Icon1} alt=""/>
      <div className="fs-ss-40 fw-600 text-ss-secondary py-3">
        30
      </div>
      <div className="fs-ss-20 fw-500 lh-sm">
        Years of Services
      </div>    
    </div>

    <div className="col-11 col-md-3 text-center">
    <img class="w-logo2" src={Icon2} alt=""/>
      <div className="fs-ss-40 fw-600 text-ss-secondary py-3">
        40000
      </div>
      <div className="fs-ss-20 fw-500 lh-sm">
        Procedures Performed
      </div>    
    </div>

    <div className="col-11 col-md-3 text-center">
    <img class="w-logo" src={Icon3} alt=""/>
      <div className="fs-ss-24 fw-700 text-ss-secondary text-uppercase lh-sm py-3">
        Too many <br /> to count
      </div>
      <div className="fs-ss-20 fw-500 lh-sm">
        Happy Clients
      </div>    
    </div>
    </div>
    </section>

    <section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-evenly align-items-center gy-4 ">
      <div className="col-10 col-lg-5 d-none d-lg-block">
      <img className='w-100 px-lg-5' src={Img2} alt="" />        
        </div>
        <div className="col-11 col-lg-6">
          <div className=''>
            <div className='fs-ss-32 fw-600 lh-sm'>
            How does ESG compare to other weight loss treatments?
            </div>
            <div className='pt-4'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            We offer a unique approach to weight loss. Our procedures and caring team help you transform your life. Whether it's ESG, colonoscopy, or Endoscopy, we're here to support you at every step. Say goodbye to fad diets and temporary fixes - As a weight loss clinic in New York, we'll help you become a healthier, happier person.   
            </div>
          </div>
        </div>
        <div className="col-10 col-lg-5 d-block d-lg-none">
        <img className='w-100 px-lg-5' src={Img2} alt="" />        
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
          <div className='text-center'>
          <div className='fs-ss-32 fw-600 lh-sm text-uppercase'>
          OUR PAYMENT PLANS <br className='' /> 
            <div className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm pt-2"> Your Non-Surgical Path to Weight Loss Success </div>
            </div>
          </div>
      <div className="row justify-content-center text-center pt-4 pt-lg-5 gy-4">
      <div className="col-serv3 text-center bg-primary rounded-4 px-4 py-5 me-lg-3">
      <div className="">
      <div className=' fw-600 text-center bg-dark border-0 rounded-5 border-0 text-white fs-ss-18 px-2 py-2'>
        ESG
      </div>
      <div className="fs-ss-26 fw-600 text-white py-3">
        $11,000.00
      </div>
      <div className="fs-ss-14 fw-400 text-white lh-sm">
      No interest for 24 months monthly payments of $458.00 <div className="py-2" /> Payment starts as low as $297 Call us for more details
      </div>
      </div>
      </div>

      <div className="col-serv3 text-center bg-primary rounded-4 px-4 py-5 me-lg-3">
      <div className="">
      <div className=' fw-600 text-center bg-dark border-0 rounded-5 border-0 text-white fs-ss-18 px-2 py-2'>
        BALLOON
      </div>
      <div className="fs-ss-26 fw-600 text-white py-3">
        $7,500.00
      </div>
      <div className="fs-ss-14 fw-400 text-white lh-sm">
      No interest for 12 months monthly payments of $625.00 <div className="py-2" /> Payment starts as low as $191 Call us for more details 
      </div>
      </div>
      </div>

      <div className="col-serv3 text-center bg-primary rounded-4 px-4 py-5">
      <div className="">
      <div className=' fw-600 text-center bg-dark border-0 rounded-5 border-0 text-white fs-ss-18 px-2 py-2 text-uppercase'>
        Gastric Revisions
      </div>
      <div className="fs-ss-26 fw-600 text-white py-3">
        $11,000.00
      </div>
      <div className="fs-ss-14 fw-400 text-white lh-sm">
      No interest for 24 months monthly payments of $458.00 <div className="py-2" /> Payment starts as low as $297 Call us for more details
      </div>
      </div>
      </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-fluid text-ss-secondary bg-light py-5">
    <section className="container-lg">
      <div className="row justify-content-evenly align-items-center gy-4 ">
      <div className="col-10 col-lg-5 d-none d-lg-block">
      <img className='w-100 px-lg-5' src={Img2} alt="" />        
        </div>
        <div className="col-11 col-lg-6">
          <div className=''>
            <div className='fs-ss-32 fw-600 lh-sm'>
            DR FARZAD FOROHAR, M.D 
            </div>
            <div className='pt-4'/>
            <div className='fs-ss-18 fw-600 lh-para'>
            Dr. Forohar has performed the most number of ESG procedures in Long Island. 
            <div className="py-1" />
            Following ESG or Gastric Balloon procedure clients will have complimentary 
            <div className="py-1" />
            *One year of follow up with Dr. Forohar 
            <div className="py-1" />
            *One year of dietary consultation with a bariatric dietitian or nutritionist.
            </div>
          </div>
        </div>
        <div className="col-10 col-lg-5 d-block d-lg-none">
        <img className='w-100 px-lg-5' src={Img2} alt="" />        
        </div>
      </div>
    </section>
    </section>

    <section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary text-lg-center">
          <div className="row justify-content-center text-center">
            <div className="col-11 col-lg-7 bg-grey-2 px-2 pt-2 rounded-3 shadow-ss-1 btn-black">
              <video className='w-100 rounded-3' src={Video1} autoPlay={true} loop="true" muted controls />
            </div>
            </div>      
    </section>

    <section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary text-lg-center">
      <div className="row justify-content-center">
        <div className="col-11">
          <div className=''>
            <div className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            Non-Surgical vs Surgical
            </div>
            <div className='pt-4'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            We offer a unique approach to weight loss. Our procedures and caring team help you transform your life. Whether it's ESG, colonoscopy, or Endoscopy, we're here to support you at every step. Say goodbye to fad diets and temporary fixes - As a weight loss clinic in New York, we'll help you become a healthier, happier person. We offer a unique approach to weight loss. Our procedures and caring team help you transform your life. Whether it's ESG, colonoscopy, or Endoscopy, we're here to support you at every step. Say goodbye to fad diets and temporary fixes - As a weight loss clinic in New York, we'll help you become a healthier, happier person.
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-fluid bg-commas px-4">
        <div className="text-center">
        <div className='fs-ss-32 fw-600 lh-sm text-uppercase'>
          CLIENT TESTIMONIALS <br className='' /> 
          <span className="text-ss-primary fw-500 text-capitalize fs-ss-22"> It's always the word of mouth that's the best advice </span>
        </div>
      </div>
      <div className="container-fluid">
      <div className="row justify-content-center align-items-center pt-4 pt-md-5 mt-lg-4">
      <div className="col-12 col-md-10">
        <Testimonials/>
      </div>
      </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-fluid text-ss-secondary text-lg-center bg-pink py-5">
      <div className="row justify-content-center py-4">
        <div className="col-11">
          <div className=''>
            <div className='fs-ss-40 fw-600 lh-sm'>
            Start your journey today!
            </div>
            <div className="pt-4 mt-lg-3">
            <Link to="/book-an-appointment" className='text-decoration-none py-1 fw-600 bg-white text-ss-secondary rounded-5 fs-ss-16 px-3 border-white border-1 border'>
            Book an Appointment
          </Link> 
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer/>
    </>
  );
}

export default LandingPage;
